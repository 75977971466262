<template>
  <div class="hp-body-bg d-flex flex-column" style="min-height: 100vh">
    <NoUserNavbar />
    <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
      class="progress-align-center"
    ></v-progress-circular>
    <v-container class="text-center container-spacing">
      <h6 class="headline font-weight-semibold text-center">
        {{ $t("Packages") }}
      </h6>
      <p
        class="subtitle-1 dark--text mt-4 mb-lg-3 mb-2 text-center mx-auto"
        :style="
          $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
            ? ''
            : 'max-width: 50%'
        "
      >
        {{
          $t(
            "Select one of our affordable packages and connect with your therapist via Chat, Audio or Video"
          )
        }}
      </p>
      <v-row v-if="sponserType === 'mySelf'">
        <v-col cols="12">
          <v-btn @click="openPromoCodeForm" color="primary" text>
            {{ $t("Do you have a Corporate Benefits code? Continue here") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-container justify-center style="max-width: 1150px" class="pb-0 mt-10">
        <v-row class="mt-0 justify-center">
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="px-xl-4 py-xl-4"
            v-if="showIndividual"
          >
            <v-card
              class="rounded-lg text-start d-flex flex-column card-with-animation fill-height pb-4 pt-16"
              elevation="2"
            >
              <v-card-title class="py-0">
                <h5
                  class="font-weight-bold px-5 mb-5 w-100"
                  :class="$vuetify.rtl ? 'subtitle-2' : 'subtitle-1'"
                >
                  <span v-if="!$vuetify.rtl">
                    {{ $t("AED") }}
                  </span>

                  <template>
                    <del>450</del>
                    300
                    <span v-if="$vuetify.rtl">
                      {{ $t("AED") }}
                    </span>
                    /
                    <sub class="font-weight-light font-size-12">
                      {{ $t("Session") }}
                    </sub>
                  </template>
                </h5>
              </v-card-title>
              <div class="px-8 has-border-bottom">
                <h4>{{ $t("Initial Consultation") }}</h4>
                <p class="black--text caption text--lighten-3 mt-2">
                  {{ $t("Start therapy with an individual live session") }}
                </p>
              </div>

              <div class="d-flex flex-column fill-height">
                <v-card-text class="px-8 pb-0">
                  <v-list class="pt-0">
                    <v-list-item
                      dense
                      v-for="feature in individual"
                      class="px-0 font-weight-semibold caption"
                      :key="feature.name"
                      :class="feature.included ? '' : 'muted--text'"
                    >
                      <img
                        v-if="feature.included"
                        src="../../../assets/check-primary.svg"
                        class="me-2"
                        width="20"
                      />
                      <img
                        v-else
                        src="../../../assets/close-primary.svg"
                        class="me-2"
                        width="20"
                      />
                      {{ feature.name }}
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </div>

              <v-card-actions class="mt-0 pb-5 px-8 pt-0">
                <v-btn
                  :loading="loading"
                  color="primary"
                  block
                  class="mx-auto getStartedButton px-5 py-3"
                  @click="createLiveSessionAction"
                >
                  {{ $t("Get Started") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <template v-for="(subscription_, key) in subscriptions">
            <v-col v-if="key != 'basic'" cols="12" sm="6" md="4" :key="key">
              <BillingCardV2
                :subscriptionProp="subscription_"
                :subscriptionKey="key"
                @onAction="billingAction"
              />
            </v-col>
          </template>
        </v-row>
        <!-- <v-row>
          <v-col cols="auto" class="mx-auto my-5">
            <v-btn :to="{name: 'useCorporateCoupon'}" color="primary">{{ $t("Use Corporate Code")}}</v-btn>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col>
            <p class="caption mb-0">
              {{
                $t(
                  "Your session credits will not expire when your subscription ends. They will remain available for you to use at a later time."
                )
              }}
            </p>
            <p class="caption">
              {{ $t("All prices are exclusive of 5% VAT") }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <NoUserFooter />
    <v-dialog v-model="showPromoDialog" width="700">
      <verify-employee-code
        @close="showPromoDialog = false"
      ></verify-employee-code>
    </v-dialog>
  </div>
</template>

<script>
import NoUserNavbar from "@/components/layout/NoUserNavbar";
import NoUserFooter from "@/components/layout/NoUserFooter";

import UserMixin from "@/mixins/UserMixin";
import { mapActions, mapState, mapGetters } from "vuex";
import BillingCardV2 from "@/components/Billing/BillingCardV2";

import { eventBus } from "@/main";
import therapistMixin from "@/mixins/TherapistMixin";
import VerifyEmployeeCode from "@/components/InitialAssessment/VerifyEmployeeCode.vue";

export default {
  name: "Pricing",
  mixins: [UserMixin, therapistMixin],
  components: {
    BillingCardV2,
    VerifyEmployeeCode,
    //  ChangeLanguage,
    NoUserNavbar,
    NoUserFooter,
    // FitAssessmentDialog,
    // Logout
  },
  data: () => {
    return {
      // fitAssessment: false,
      dialog: false,
      loading: false,
      showIndividual: false,
      showVariants: false,
      // addLiveSessionProp: [
      //   {
      //     id: 1,
      //     name: "1 live Session",
      //     discount: 0,
      //     amount: "350",
      //     chargedAmount: "350",
      //     value: 1,
      //     checked: true,
      //   },
      //   {
      //     id: 3,
      //     name: "3 Sessions",
      //     discount: 5,
      //     amount: "333",
      //     chargedAmount: "999",
      //     value: 3,
      //     checked: false,
      //   },
      //   {
      //     id: 6,
      //     name: "6 Sessions",
      //     discount: 10,
      //     amount: "315",
      //     chargedAmount: "1,890",
      //     value: 6,
      //     checked: false,
      //   },
      // ],

      callbackAction: null,
      showPromoDialog: false,
    };
  },

  computed: {
    ...mapGetters("subscription", ["subscriptions"]),
    ...mapGetters(["lang"]),
    ...mapState("preRegister", ["sponserType"]),

    // numberOfLiveSessionsSelected() {
    //   return this.addLiveSessionProp.find((e) => e.checked)?.value || 1;
    // },

    individual() {
      return [
        {
          name: this.$t("50min Live Session"),
          included: true,
        },
        {
          name: this.$t("Future-Self Journal"),
          included: true,
        },
        {
          name: this.$t("20min Free Initial Consultation"),
          included: false,
        },
        {
          name: this.$t("Chat Messaging"),
          included: true,
        },
      ];
    },
  },

  methods: {
    ...mapActions("subscription", ["getSubscriptions", "createLiveSession"]),
    openPromoCodeForm() {
      this.showPromoDialog = true;
    },

    // selectPlan(price) {
    //   if (!price.checked) {
    //     this.addLiveSessionProp.map((item) => {
    //       item.checked = false;
    //     });
    //
    //     price.checked = true;
    //   }
    // },

    // test1() {
    //   this.paymentTrue = true
    //   console.log("payment false")

    // },

    // test2() {
    //   console.log("payment true")
    // }

    createLiveSessionAction() {
      this.openFitAssessment(this.createLiveSessionActionCb.bind(this));
    },

    openFitAssessment(cb) {
      this.callbackAction = cb;
      this.continueFitAssessment();
      // this.$refs.fitAssessmentDialogRef.open();
      // this.fitAssessment = true;
      // this.$router.push({
      //   name: "FitAssessment",
      // });
    },

    continueFitAssessment() {
      this.$emit("nextStep", this.callbackAction);
      // this.openTerms(this.callbackAction);
    },

    async createLiveSessionActionCb({ teenTerms } = {}) {
      let therapistId = this.demoTherapist?._id;

      const createLiveSessionData = {
        // count: this.numberOfLiveSessionsSelected,
        name: "LIVE_SESSION_INITIAL_1",
        therapistId,
      };

      if (teenTerms) {
        createLiveSessionData.teenTerms = teenTerms;
      }

      this.loading = true;
      let liveSessionActionRes = await this.createLiveSession(
        createLiveSessionData
      );
      this.loading = false;
      window.location.replace(liveSessionActionRes.url);
    },

    billingAction(action) {
      this.openFitAssessment(action);
    },
  },

  // created() {
  // if (this.hasNoTherapist || this.isTherapist) {
  //   this.$router.replace({ name: "Profile" });
  // }
  // },

  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        this.loading = true;
        await this.getSubscriptions();
        this.loading = false;
        this.showIndividual = true;
      },
    });

    eventBus.$on("pricingDialog:action", (e) => {
      this.dialog = e;
    });
  },
};
</script>

<style scoped lang="scss">
.progress-align-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
  z-index: 1000;
}

.text-right {
  text-align: right !important;
}

::v-deep.v-btn {
  height: auto !important;
}

::v-deep.v-btn:not(.getStartedButton) .v-btn__content {
  white-space: normal;
  flex-wrap: wrap;
  flex: auto;
  justify-content: start;
  padding: 10px;
}

::v-deep.v-btn.getStartedButton {
  box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35) !important;
  border-radius: 15px;
}

::v-deep.v-btn:not(.getStartedButton) .v-btn__content {
  white-space: normal;
  flex-wrap: wrap;
  flex: auto;
  justify-content: start;
  padding: 10px;
}

::v-deep.v-btn.primary .v-chip.primary {
  background-color: white !important;
  color: #3475b2 !important;
  /* color:  */
}

::v-deep.v-btn.v-btn--outlined:not(.v-btn--plain) {
  /* box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35); */
  border-radius: 15px !important;
}
.theme--light .hp-body-bg {
  background-position: top left, top right;
  /* background-attachment: fixed; */
  background-image: url("../../../assets/background-gradient-left.png"),
    url("../../../assets/background-gradient-right.png");
}
</style>
