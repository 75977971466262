<template>
  <div>
    <p class="body-2 text-center">
      IF USER EXPERIENCES A MEDICAL EMERGENCY PLEASE CALL:
    </p>
    <h4 class="mt-5 mb-5 text-center">
      <strong>FOR THOSE RESIDING IN THE UAE:</strong>
    </h4>

    <p class="body-2 text-center">
      999 FOR THE POLICE; 998 FOR AMBULATORY SERVICES
    </p>

    <h4 class="mt-5 mb-5 text-center">
      <strong>FOR THOSE RESIDING IN COUNTRIES OTHER THAN THE UAE:</strong>
    </h4>

    <p class="body-2 text-center">LOCAL AMBULANCE OR POLICE HOTLINE</p>

    <p class="body-2 text-center">
      IMMEDIATELY STOP USING THE PLATFORM IN CASES OF EMERGENCIES. THE PLATFORM
      IS NOT DESIGNED FOR, NOR IS IT INTENDED TO BE USED IN, EMERGENCY
      SITUATIONS. NEITHER THE COMPANY NOR THE COUNSELORS CAN PROVIDE EMERGENCY
      ASSISTANCE.
    </p>

    <p class="body-2 text-center">
      IF A USER IS CONTEMPLATING SUICIDE OR IF A USER IS CONSIDERING HARMING
      HIMSELF, HERSELF, OR OTHERS, OR IF USER FEELS THAT ANY OTHER PERSON MAY BE
      IN ANY DANGER, USER MUST STOP USING THE PLATFORM AND MUST IMMEDIATELY SEEK
      EMERGENCY ASSISTANCE.
    </p>

    <p class="body-2 text-center">
      TalkTime (www.talktime.ae) is a website platform (the "Platform") owned
      and maintained by TalkTime Mental Health Services Limited, a Private
      Company registered in the Dubai International Financial Center ("DIFC")
      with License Number CL5011 ("TalkTime" or the "Company").
    </p>

    <p class="body-2 text-center">
      These user terms are intended to be used by those between the ages of
      13-17 years old and who are being registered on the Platform by their
      parents or legal guardians. You, the user (hereafter the "User") shall
      refer to the minor child and the legal guardian of the child. No minor
      child may use or register on the Platform not unless the legal guardian
      also completes and signs the Company’s parental consent form.
    </p>

    <p class="body-2 text-center">
      The User must carefully read the terms and conditions contained herein
      before using the Platform. By using the Platform, the User agrees to abide
      by the terms and conditions contained herein, and the terms and conditions
      contained herein constitute a binding legal obligation on the User (the
      "User Terms").
    </p>

    <p class="body-2 text-center">
      By accepting these User Terms, User affirms that he or she is the person
      identified in the registration process. User represents that he or she has
      full legal authority to enter into these User Terms and agrees to be bound
      by the terms and conditions set forth herein.
    </p>

    <h4 class="mt-5 mb-5">
      <strong
        >SECTION 1: COMPLIANCE WITH APPLICABLE LAWS AND LAW ENFORCEMENT</strong
      >
    </h4>
    <p class="body-2">
      1.1 The User’s consent to enter into these User Terms by using the
      Platform includes the User’s consent that these User Terms shall be
      governed by and construed in accordance with with all Applicable Laws of
      the United Arab Emirates and the Dubai International Financial Center (the
      "DIFC") which may include, without limitation: (1) the Constitution of the
      United Arab Emirates (Federal Law 1 of 1971); (2) the Penal Code (Federal
      Law 3 of 1987 as amended); (3) the Cyber Crime Law (Federal Law No. 5 of
      2012 regarding "Information Technology Crime Control", as amended by
      Federal Law No. 12 of 2016 and Federal Law by Decree No. 2 of 2018); and
      the DIFC Law No. 5 of 2020, as amended, regarding personal data processing
      and data protection (collectively the "Applicable Laws", each being an
      "Applicable Law").
      <br /><br />
      1.2 The Company reserves the right to amend or terminate any portion of
      these User Terms without prior notice and for any reason.
      <br /><br />
      1.3 The Company may be obligated to comply with requests from law
      enforcement and other governmental authorities for information about the
      User. The Company will provide the User with notice of these requests
      wherever possible.
    </p>
    <h4 class="mt-5 mb-5">
      <strong>SECTION 2: COUNSELORS AND THE PROVIDER SERVICES</strong>
    </h4>
    <p class="body-2">
      2.1 The Platform is designed to be used by creating an account with the
      Company for purposes of connecting the User with a counselor or a similar
      service provider (each a "Counselor" and collectively the "Counselors").
      Counselors will deliver professional counseling or similar services to the
      User exclusively through the Platform (the "Provider Services").
      <br /><br />
      2.2 The Company provides, through the Platform, a panel of Counselors with
      whom the User can interact for purposes of seeking and receiving Provider
      Services. The Company provides information about the Counselors on the
      Platform for the User to review. However, the Company does not necessarily
      conduct due diligence on the accuracy of all information included on
      Counselor profiles that are accessible on the Platform. The User is
      responsible for making independent decisions about Counselors the User is
      seeking Provider Services from. The User is also responsible for making
      independent decisions about which mode or modes of communication to use
      when receiving Provider Services (Users may choose to communicate with
      Counselors with: (1) real-time online messaging; (2) teleconferences; or
      (3) video consultations). The User agrees that the Company will not be
      responsible for any act or omission made by Counselors during any
      consultation.
      <br /><br />
      2.3 The User agrees not to record, resell or recreate any session or other
      content without the Company’s prior, written consent.
      <br /><br />
      2.4 The Company does not host services that require professionals to hold
      active medical licenses. The Company does not provide medical advice. Any
      medical advice or medical-related representations provided by Counselors
      amount to a breach of the Counselor’s terms and conditions, for which the
      Counselor shall bear exclusive responsibility and be subject to any and
      all liabilities that may arise from such a breach.
      <br /><br />
      2.5 Whenever using the Platform, the User shall interact with the
      Counselor at the User’s own risk. The Company will not be liable for any
      harm or damages arising out of interactions between the User and any
      Counselor. The Company specifically disclaims any responsibility or
      liability that may arise from any miscommunication or misinformation
      arising from communications between any Counselor and any User.
      <br /><br />
      2.6 Any Counselor that the User is connected with through the use of the
      Platform is an independent contractor of the Company who does not have
      authority to bind or speak on behalf of the Company in any way. The
      Company's role is limited to providing technologies that enable the
      Provider Services to be provided to the User by Counselors, and Counselors
      assume exclusive responsibility for the delivery of the Provider Services.
      The Company has no obligation to supervise or monitor any of the Provider
      Services provided to Users.
      <br /><br />
      2.7 The User understands, acknowledges, and agrees that Provider Services
      may not be an appropriate solution for the User’s needs. The User also
      acknowledges and agrees that the Provider Services are not a substitute
      for professional services that are delivered on an in-person basis. If the
      User believes that the Provider Services do not meet User’s needs or
      expectations, the User must either select a different Counselor or cease
      using the Platform.
      <br /><br />
      2.8 By creating an account, User agrees to receive communications from the
      Company from time to time, such as e-mails, text messages, reminders,
      newsletters, and other updates. Users may opt-out of these communications
      by sending an e-mail to the Company.
      <br /><br />
      2.9 The Company does not endorse any brand or advertise on behalf of any
      business, clinic, or other institution that may be owned, operated, or
      supported by any Counselor.
      <br /><br />
      2.1 The User agrees and understands that the Counselor may, based on their
      professional opinion, request to contact one or more of the User’s
      emergency contacts. In the event that a User fails to provide their
      emergency contact information and/or refuses to allow the Counselor to
      contact such emergency contacts, then the User must immediately contact
      their local ambulance or emergency hotline and their service and account
      with the Company will be immediately suspended.
      <br /><br />
      USER AGREES AND UNDERSTANDS THAT THE COUNSELOR MAY NOT BE A LICENSED
      MENTAL HEALTH IN THE JURISDICTION WHERE THE USER RESIDES.
    </p>
    <h4 class="mt-5 mb-5">
      <strong>SECTION 3: BOOKING SESSIONS AND MAKING PAYMENTS</strong>
    </h4>
    <p class="body-2">
      3.1 Users must schedule times to receive Provider Services through the
      Platform. In order to receive Provider Services, the User must pay a
      subscription fee covering Provider Services for a minimum of two (2)
      weeks. All payments made in order to schedule and receive Subscriber
      Services shall be made exclusively through the Platform. The User will
      also be required to remit payment for Provider Services in advance of
      receiving Provider Services.
      <br /><br />
      3.2 The Platform requires Users to provide their email address, username,
      and password only when registering for an account. The Company will not
      disclose this information to parties outside the Platform unless the
      Company is compelled to do so by law enforcement, government agencies, or
      by court order.
      <br /><br />
      3.3 By providing the Company with an acceptable form of payment, the User
      authorizes the Company to bill that form of payment for any amounts the
      User owes to the Company.
      <br /><br />
      3.4 Whenever remitting payment, the User represents that the User is fully
      authorized to make such payment. User assumes all liability for
      misrepresentations regarding its authority to make payments with forms of
      payment it uses on the Platform.
      <br /><br />
      3.5 The User agrees that all payments shall be nonrefundable, unless
      otherwise provided for by the Company in writing.
      <br /><br />
      3.6 If the Platform malfunctions such that Provider Services cannot be
      provided, the User may notify the Company of the specific circumstances by
      way of email to request a refund. After receiving such notification, the
      Company will review the circumstances and provide a refund to the User if,
      in the Company’s sole discretion, it has determined that the User is
      entitled to the requested refund.
      <br /><br />
      3.7 The User agrees that no payment will be refunded in the event that the
      User has breached any of its duties or obligations under these User Terms,
      made material misrepresentations under these User Terms, or has violated
      any Applicable Law while using the Platform.
      <br /><br />
      3.8 The Company reserves the right to modify, terminate, or suspend any
      User or form of payment at its sole discretion.
      <br /><br />
      3.9 The User may cancel or reschedule any session with more than 12-hours
      advance written notice. In the event the User fails to attend any session
      without providing 12-hour advance written notice, such session shall be
      deemed attended by the User.
    </p>
    <h4 class="mt-5 mb-5">
      <strong>SECTION 4: INTELLECTUAL PROPERTY AND THIRD PARTIES</strong>
    </h4>
    <p class="body-2">
      4.1 The Company is the owner or the licensee of all intellectual property
      rights for technologies constituting the Platform itself.
      <br /><br />
      4.2 The materials published on the Platform are protected by applicable
      copyright laws. The User shall not download, copy, use, or modify any
      materials available on the Platform for any other purpose other than for
      personal use. If User downloads, copies, uses, or modifies materials
      available on the Platform for User’s personal use, User must destroy all
      such materials immediately upon completion of such personal use.
      <br /><br />
      4.3 The User shall not use the Platform to violate any laws.
      <br /><br />
      4.4 The User shall not attempt to hack, disassemble, or reverse-engineer
      the software constituting the Platform.
      <br /><br />
      4.5 The Platform may contain content, products, or services, hyperlinks to
      third-party websites offering content, products, services, or
      advertisements (the "Third Party Content"). The Company bears no
      responsibility for the specific content presented by Third Party Content,
      and the Company shall not be liable for any damage or loss which may arise
      from the User’s engagement with Third Party Content.
      <br /><br />
      4.6 The Company may develop and release software updates for the Platform
      from time to time (the "Product Updates"). The User may be required to
      download an updated version of the Platform to continue accessing Provider
      Services. The Company does not guarantee that any subsequent version of
      the Platform will operate properly on User’s devices (including personal
      computers, laptops, or mobile phones). The Company is not liable for any
      loss the User may incur due to Product Updates.
    </p>
    <h4 class="mt-5 mb-5">
      <strong
        >SECTION 5: DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</strong
      >
    </h4>
    <p class="body-2">
      5.1 THE USER HEREBY RELEASES THE COMPANY, ITS PRESENT AND FUTURE OFFICERS,
      DIRECTORS, EMPLOYEES, MEMBERS, VOLUNTEERS, CONTRACTORS, REPRESENTATIVES,
      PARENTS, OWNERS, AFFILIATES, AGENTS, SUCCESSORS, AND ASSIGNS
      (COLLECTIVELY, THE "COMPANY RELATED PARTIES") AND AGREES TO HOLD THE
      COMPANY RELATED PARTIES HARMLESS FROM ANY AND ALL CAUSES OF ACTION AND
      CLAIMS OF ANY NATURE RESULTING FROM THE PROVIDER SERVICES OR THE PLATFORM,
      INCLUDING (WITHOUT LIMITATION) ANY ACT, OMISSION, OPINION, RESPONSE,
      ADVICE, SUGGESTION, INFORMATION, AND/OR SERVICE OF ANY COUNSELOR AND/OR
      ANY OTHER CONTENT OR INFORMATION ACCESSIBLE THROUGH THE PLATFORM.
      <br /><br />
      5.2 THE USER UNDERSTANDS, AGREES, AND ACKNOWLEDGES THAT THE PLATFORM IS
      PROVIDED "AS IS", WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES OF ANY KIND
      (INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, NON-INFRINGEMENT, SECURITY,
      FITNESS FOR A PARTICULAR PURPOSE, AND ACCURACY). USE OF THE PLATFORM SHALL
      BE AT THE USER’S OWN RISK. TO THE FULLEST EXTENT OF THE LAW, THE COMPANY
      EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, BOTH EXPRESSED AND IMPLIED.
      <br /><br />
      5.3 THE USER UNDERSTANDS, AGREES, AND ACKNOWLEDGES THAT NEITHER THE
      COMPANY NOR ANY OF ITS AFFILIATES SHALL BE LIABLE TO THE USER OR TO ANY
      THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
      PUNITIVE, OR EXEMPLARY DAMAGES.
      <br /><br />
      5.4 If Applicable Law does not allow the limitation of liability as set
      forth above, the limitation will be deemed modified solely to the extent
      necessary to comply with Applicable Law. This limitation of liability
      contained herein shall survive the termination or expiration of these User
      Terms.
    </p>
    <h4 class="mt-5 mb-5">
      <strong>SECTION 6: USER REPRESENTATIONS AND WARRANTIES</strong>
    </h4>
    <p class="body-2">
      6.1 The User represents that he or she is legally able to enter into these
      User Terms.
      <br /><br />
      6.2 The User represents that he or she is legally able to consent to
      receive Provider Services.
      <br /><br />
      6.3 The User represents that all the information User provides on the
      Platform, either now or in the future, is complete, accurate, and true.
      Furthermore, the User agrees that during the term of these User Terms,
      User will take all reasonable steps to maintain and update User’s
      information to ensure that all such information continues to be complete,
      accurate, and true.
      <br /><br />
      6.4 The User is responsible for protecting the confidentiality of User’s
      password. The User is advised to change his or her password frequently and
      to take extra care in safeguarding his or her account.
      <br /><br />
      6.5 The User shall immediately notify the Company in the event of any
      unauthorized use of User’s account, or if User is concerned that User’s
      account has been accessed by any third party.
      <br /><br />
      6.6 The User agrees to use the Platform for the sole purpose of seeking
      and receiving Provider Services. The User may only use the Platform for
      User’s own personal use; User must not use the Platform for or behalf of
      any other person, entity, or organization.
      <br /><br />
      6.7 The User acknowledges and agrees that the Company shall not be liable
      for any loss or damage incurred as a result of unauthorized access of
      User’s account, either with or without the User’s consent or knowledge.
      <br /><br />
      6.8 The User acknowledges and agrees that the User is exclusively
      responsible for all activities performed using the User’s account. The
      User further acknowledges and agrees that the Company may hold the User
      liable for any damages or losses incurred as a result of the use of User’s
      account by any third party, whether or not such use was authorized by the
      User, and the User shall indemnify the Company for all relevant damages or
      losses.
      <br /><br />
      6.9 The User shall indemnify, defend and hold the the Company harmless
      from and against any and all claims, losses, causes of action, demands,
      liabilities, costs, or expenses (including, but not limited to, litigation
      and reasonable attorneys' fees and expenses) arising out of or relating to
      any of the following: (1) User’s access to or use of the Platform; (2) any
      actions made with the User’s account whether by the User or a third party;
      (3) the User’s violation of any of the provisions of these User Terms; (4)
      non-payment for any of the services (including Provider Services) which
      were provided through the Platform; (5) the User’s violation of any third
      party right, including, without limitation, any intellectual property
      right, publicity, confidentiality, property or privacy right. This clause
      shall survive the expiration or termination of these User Terms.
      <br /><br />
      6.10 The User shall not use any other Account on the Platform for any
      reason. The User may only have access to and use one (1) Account.
      <br /><br />
      6.11 The User shall not interfere with, or attempt to interfere with, any
      of the Company’s systems, services, servers, networks, or infrastructure
      (including the Platform and it’s software components).
      <br /><br />
      6.12 The User shall not: (1) send unsolicited messages or emails on the
      Platform which advertise or promote goods or services; (2) interfere with
      or damage the Company’s software or systems through the use of malicious
      software (also known as "malware"), code, or computer viruses; (3) engage
      in unlawful, harassing, invasive, abusive, threatening, vulgar, obscene,
      racist or potentially harmful behavior toward others who may be using the
      Platform (including Counselors and other users); (4) publish or display
      content that infringes upon the intellectual property rights of third
      parties; (5) publish or display content that may otherwise harm or injure
      a third party; (6) publish or display any content which may constitute,
      proximately cause or encourage a crime, tort, or violate any Applicable
      Law or regulation; or (7) solicit personal or Account information from
      Counselors or other users of the platform.
      <br /><br />
      6.13 Whenever using the Platform, the User shall not engage in conduct
      that would violate any applicable domestic or international law, statute,
      ordinance, rule, regulation or ethical code.
      <br /><br />
      6.14 All interactions and communications between the User and any
      Counselor shall be carried out exclusively through the Platform. The User
      shall not communicate or interact with the Counselors outside the Platform
      without prior, written consent from the Company for a period of at least
      one (1) calendar year from the date of their last session with any
      Counselor on the Platform.
      <br /><br />
      6.15 In all of its electronic communications with the Company or with
      Counselors, the User agrees to check and scan all relevant attachments and
      files for virus or malicious software.
    </p>
    <h4 class="mt-5 mb-5">
      <strong
        >SECTION 7: PLATFORM INTERRUPTIONS, MODIFICATIONS, AND
        TERMINATION</strong
      >
    </h4>
    <p class="body-2">
      7.1 The Company may modify, suspend, disrupt or discontinue the Platform,
      any part of the Platform, or the ability to use the platform at any time
      with or without notice. The Company may also suspend or terminate the
      User’s account at its discretion. The Company shall not be liable for any
      losses or damages that may arise from any of the aforementioned actions.
      <br /><br />
      7.2 The User understands and acknowledges that the Platform’s
      functionality depends in part on the performance of third-party internet
      service providers and other third-party software components, tools, or
      dependencies which may not be under the Company’s control. While the
      Company makes commercially reasonable efforts to ensure the Platform’s
      reliability and accessibility, the Company cannot guarantee that the
      Platform will be accessible, consistent, timely, or error-free at all
      times. Accordingly, the Company shall not be liable for any interruptions,
      time-lags, or disconnections that may occur while the User is accessing or
      receiving Provider Services.
    </p>
    <h4 class="mt-5 mb-5">
      <strong>SECTION 8: MANDATORY ARBITRATION</strong>
    </h4>
    <p class="body-2">
      All disputes arising out of or in connection with these User Terms shall
      be finally settled under the Rules of Arbitration of the International
      Chamber of Commerce (the "Rules") by a single arbitrator appointed in
      accordance with said Rules. The parties expressly agree, pursuant to
      Article 30(2)(b) of the Rules, that the Expedited Procedure Rules shall
      apply irrespective of the amount in dispute. The place of arbitration
      shall be in Dubai, United Arab Emirates. The language of the arbitration
      shall be English.
    </p>
    <h4 class="mt-5 mb-5"><strong>SECTION 9: NOTICES</strong></h4>
    <p class="body-2">
      9.1 The Platform is not intended for the provision of clinical diagnosis
      requiring an in-person evaluation. The Platform is not intended for
      diagnoses and/or treatments prescribing the use of drugs or medical
      treatment, and the User should disregard any such advice if delivered
      through the platform.
      <br /><br />
      9.2 Users are urged not to disregard, avoid, or delay in obtaining
      in-person care from their doctor or other qualified professional because
      of the information or advice User received through the Platform.
    </p>
  </div>
</template>

<script>
export default {
  name: "TeenTermsText",
};
</script>

<style scoped></style>
