<template>
  <div>
    <v-dialog
      persistent
      v-model="dialog"
      scrollable
      class="mb-10"
      max-width="700px"
    >
      <v-card class="rounded-card-with-shadow">
        <v-card-title class="justify-center flex-column">
          <h2 class="mb-5 black--text headline font-weight-bold">
            {{ $t("Terms of Use") }}
          </h2>
          <!-- <h2 class="mb-5 black--text text-center headline font-weight-bold">
            {{ $t("Emergency contact details") }}
          </h2>
          <p class="subtitle-1 dark--text mb-lg-6 mb-4 text-center">
            {{ $t("The below details will only be used in case of emergency") }}
          </p> -->
        </v-card-title>

        <v-card-text class="px-md-10">
          <TeenTermsTextArabic v-if="lang === 'ar'" />
          <TeenTermsText v-else />
        </v-card-text>

        <v-card-actions class="pb-6 pt-5 px-md-10 flex-column">
          <v-checkbox
            v-model="checkbox"
            :class="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'caption' : ''
            "
            :label="
              $t(
                'By ticking, your are confirming that you have read, understood and agree to TalkTime Terms and Conditions'
              )
            "
          ></v-checkbox>
          <v-btn
            block
            color="primary"
            @click="openDetails"
            :disabled="!checkbox"
            class="pe-3 mb-5"
          >
            {{ $t("Accept") }}
          </v-btn>

          <v-btn block text color="black" class="pe-3" @click="close">
            {{ $t("Decline") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detailsDialog" scrollable max-width="700px">
      <v-card class="pa-md-5 px-0">
        <v-card-title class="justify-center flex-column">
          <!-- <h2 class="mb-5 black--text headline font-weight-bold">
            {{ $t("ACCEPTANCE OF TERMS") }}
          </h2>
          <p class="subtitle-1 dark--text mb-lg-6 mb-4 text-center">
            {{
              $t(
                "By signing below, I hereby agree to abide by the Company's terms of use as located here URL:"
              )
            }}
          </p> -->
          <h2 class="mb-5 black--text text-center headline font-weight-bold">
            {{ $t("Emergency contact details") }}
          </h2>
          <p class="subtitle-1 dark--text mb-lg-6 mb-4 text-center">
            {{ $t("The below details will only be used in case of emergency") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="align-center">
              <v-col
                cols="12"
                md="6"
                class="px-0 px-md-2"
                :class="textAlignment"
                ><h5 class="body-2 font-weight-bold">
                  {{ $t("Parent or Guardian Name") }}
                </h5></v-col
              >

              <v-col
                cols="12"
                md="6"
                class="px-0 px-md-2"
                :class="textAlignment"
              >
                <v-text-field
                  dense
                  v-model="parentName"
                  :label="$t('Parent Name')"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="px-0 px-md-2"
                :class="textAlignment"
                ><h5 class="body-2 font-weight-bold">
                  {{ $t("Email Address of Parent or Guardian") }}
                </h5></v-col
              >

              <v-col
                cols="12"
                md="6"
                class="px-0 px-md-2"
                :class="textAlignment"
              >
                <v-text-field
                  dense
                  v-model="emailAddress"
                  :label="$t('Email Address')"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="px-0 px-md-2"
                :class="textAlignment"
                ><h5 class="body-2 font-weight-bold">
                  {{ $t("Phone Number of Parent or Guardian") }}
                </h5></v-col
              >

              <v-col
                cols="12"
                md="6"
                class="px-0 px-md-2"
                :class="textAlignment"
              >
                <v-text-field
                  dense
                  v-model="phoneNumber"
                  :label="$t('Phone Number')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="flex-column">
          <v-btn
            block
            color="primary"
            class="mb-3"
            :disabled="!canContinue"
            @click="onAccept"
            >{{ $t("Continue") }}</v-btn
          >
          <v-btn block text color="black" @click="closeDetails">{{
            $t("Cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import logoSvg from "@/components/svg/Logo";
// import {mapActions} from "vuex";

import TeenTermsText from "@/components/teenTerms/TeenTermsText";
import TeenTermsTextArabic from "@/components/teenTerms/TeenTermsTextArabic";

import { mapGetters } from "vuex";

export default {
  name: "Terms",

  components: {
    TeenTermsText,
    TeenTermsTextArabic,
  },

  data: () => {
    return {
      detailsDialog: false,
      parentName: "",
      emailAddress: "",
      phoneNumber: "",
      checkbox: false,
      dialog: false,
      callbackAction: null,
    };
  },

  computed: {
    ...mapGetters(["lang"]),

    textAlignment() {
      return (this.$vuetify.rtl &&
        (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm)) ||
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm
        ? "text-start"
        : "text-end";
    },
    canContinue() {
      return (
        this.parentName.trim() &&
        this.emailAddress.trim() &&
        this.phoneNumber.trim()
      );
    },
  },

  methods: {
    // ...mapActions("user", ["logout"]),

    open(cb = null) {
      this.dialog = true;
      this.callbackAction = cb;
    },

    close() {
      this.dialog = false;
    },

    openDetails() {
      this.dialog = false;
      this.detailsDialog = true;
    },

    closeDetails() {
      this.detailsDialog = false;
      this.dialog = true;
    },

    onAccept() {
      if (typeof this.callbackAction === "function") {
        this.callbackAction({
          teenTerms: {
            childName: this.childName,
            parentName: this.parentName,
            emailAddress: this.emailAddress,
            phoneNumber: this.phoneNumber,
          },
        });
      }
      // this.close();
    },
  },
};
</script>

<style scoped lang="scss">
h4 strong {
  color: #000;
}

::v-deep.v-btn.primary {
  box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35);
}

::v-deep.v-input--checkbox {
  .v-input__slot {
    /* align-items: start; */
    text-align: start;
    /* background-color: red; */

    label {
      font-size: 14px;
      color: #000;
    }
  }
}
</style>
