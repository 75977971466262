<template>
  <!-- <v-dialog v-model="dialog" scrollable width="700" persistent> -->
  <div class="hp-body-bg d-flex flex-column py-10" style="min-height: 100vh">
    <NoUserNavbar :back-button-action="prevStep" />
    <v-container class="container-spacing">
      <v-row>
        <v-col cols="12" sm="9" class="mx-auto">
          <!-- <h2 class="font-weight-medium mb-7"></h2> -->
          <!-- <h6 class="title font-weight-semibold mb-3 mb-md-4">
            {{ $t("TalkTime Fit Assessment") }}
          </h6> -->
          <h6 class="headline font-weight-semibold text-center mb-6">
            {{ $t("Care Assessment") }}
          </h6>
          <h6 class="body-2 font-weight-medium mb-md-4 mb-3">
            {{
              $t(
                "Some people who feel distressed sometimes report that they think about harming themselves or taking their own life. Have you ever felt like this?"
              )
            }}
          </h6>
          <v-row>
            <v-col
              cols="auto"
              sm="auto"
              class="py-0"
              v-for="(a, i) in harmingThemselves"
              :key="i"
            >
              <v-btn
                elevation="0"
                :outlined="!a.checked"
                :color="!a.checked ? 'muted' : 'primary'"
                block
                :ripple="false"
                @click="toggleRadios(a, 'harmingThemselves')"
                class="me-5 py-3 mt-3 mb-3"
              >
                <div class="d-flex align-center">
                  <div class="right-aligned-checkbox">
                    <v-icon v-if="!a.checked">mdi-circle-outline</v-icon>
                    <v-icon v-else>mdi-check-circle</v-icon>
                  </div>

                  <span>{{ $t(a.name) }}</span>
                </div>
              </v-btn>
            </v-col>
          </v-row>

          <template v-if="showAttemptedPast">
            <h6 class="body-2 font-weight-medium mb-md-4 mb-3 mt-md-8 mt-6">
              {{
                $t("Have you ever attempted anything like that in the past?")
              }}
            </h6>
            <v-row>
              <v-col
                cols="auto"
                sm="auto"
                class="py-0"
                v-for="(a, i) in attemptedPast"
                :key="i"
              >
                <v-btn
                  elevation="0"
                  :outlined="!a.checked"
                  :color="!a.checked ? 'muted' : 'primary'"
                  block
                  :ripple="false"
                  @click="toggleRadios(a, 'attemptedPast')"
                  class="me-5 py-3 mt-3 mb-3"
                >
                  <div class="d-flex align-center">
                    <div class="right-aligned-checkbox">
                      <v-icon v-if="!a.checked">mdi-circle-outline</v-icon>
                      <v-icon v-else>mdi-check-circle</v-icon>
                    </div>

                    <span>{{ $t(a.name) }}</span>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <template v-if="showActThoughts">
            <h6 class="body-2 font-weight-medium mt-md-8 mt-6">
              {{ $t("How likely are you to act on these thoughts right now?") }}
            </h6>
            <span class="muted--text caption">{{
              $t("(0 not at all, 10 will definitely act them straight away)")
            }}</span>
            <v-row class="mt-md-2 mt-3">
              <v-col
                cols="auto"
                class="py-0 pe-0"
                v-for="(a, i) in actThoughts"
                :key="i"
              >
                <v-btn
                  elevation="0"
                  :outlined="!(a.value <= selectedActThoughtsValue)"
                  :color="
                    a.value <= selectedActThoughtsValue ? 'primary' : 'muted'
                  "
                  block
                  :ripple="false"
                  @click="toggleRadios(a, 'actThoughts')"
                  class="py-3 mt-3 mb-3 numbers"
                >
                  <div class="d-flex align-center">
                    <span>{{ $t(a.name) }}</span>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <template v-if="showActionTowardsCrying">
            <h6 class="body-2 font-weight-medium mb-md-4 mb-3 mt-md-8 mt-6">
              {{
                $t(
                  "Have you taken any action towards carrying out these plans?"
                )
              }}
            </h6>
            <v-row>
              <v-col
                cols="auto"
                sm="auto"
                class="py-0"
                v-for="(a, i) in actionTowardsCrying"
                :key="i"
              >
                <v-btn
                  elevation="0"
                  :outlined="!a.checked"
                  :color="!a.checked ? 'muted' : 'primary'"
                  block
                  :ripple="false"
                  @click="toggleRadios(a, 'actionTowardsCrying')"
                  class="me-5 py-3 mt-3 mb-3"
                >
                  <div class="d-flex align-center">
                    <div class="right-aligned-checkbox">
                      <v-icon v-if="!a.checked">mdi-circle-outline</v-icon>
                      <v-icon v-else>mdi-check-circle</v-icon>
                    </div>
                    <span>{{ $t(a.name) }}</span>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <!-- <v-spacer></v-spacer> -->
          <v-row>
            <v-btn
              color="primary"
              class="px-5 py-2 mx-auto mb-4"
              style="box-shadow: 0 13px 21px rgba(52, 117, 178, 0.35)"
              :disabled="!canContinue"
              @click="continueDialog"
            >
              {{ $t("Continue") }}
              <v-icon right>{{ $vuetify.rtl ? 'mdi-arrow-right mdi-rotate-180'  :  'mdi-arrow-right '}}</v-icon>
            </v-btn>
          </v-row>

          <!-- <v-spacer></v-spacer> -->
        </v-col>
      </v-row>
    </v-container>
    <TerminateAccess ref="terminateAccess" />
    <CrisisManagement ref="crisisManagementRef" @onAccept="nextStep" />

    <Terms ref="termsRef" />
    <TermsArabic ref="termsArRef" />
    <TeenTerms ref="termsTeenRef" />

    <!-- </v-dialog> -->
    <NoUserFooter />
  </div>
</template>

<script>
import NoUserNavbar from "@/components/layout/NoUserNavbar";
import NoUserFooter from "@/components/layout/NoUserFooter";
import TerminateAccess from "@/views/noUser/InitialAssessment/TerminateAccess";
import CrisisManagement from "@/components/InitialAssessment/CrisisManagement";
import { mapGetters, mapActions, mapMutations } from "vuex";
import TeenTerms from "@/components/teenTerms/TeenTerms";
import Terms from "@/components/Terms";
import TermsArabic from "@/components/TermsArabic";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "FitAssessment",

  props: {
    callbackAction: {
      defaults: null,
    },
  },

  mixins: [UserMixin],

  components: {
    TerminateAccess,
    NoUserNavbar,
    NoUserFooter,
    CrisisManagement,
    TeenTerms,
    Terms,
    TermsArabic,
  },

  data: () => ({
    harmingThemselves: [
      {
        name: "No",
        value: "no",
        checked: false,
        showNext: false,
        canContinue: true,
      },
      {
        name: "Yes",
        value: "yes",
        checked: false,
        showNext: true,
        canContinue: false,
      },
    ],

    attemptedPast: [
      {
        name: "No",
        value: "no",
        checked: false,
        showNext: true,
        canContinue: false,
      },
      {
        name: "Yes",
        value: "yes",
        checked: false,
        showNext: true,
        canContinue: false,
      },
    ],

    actThoughts: [
      {
        name: "1",
        value: 1,
        checked: false,
        showCrisis: true,
        canContinue: true,
      },
      {
        name: "2",
        value: 2,
        checked: false,
        showCrisis: true,
        canContinue: true,
      },
      {
        name: "3",
        value: 3,
        checked: false,
        showNext: true,
        canContinue: false,
      },
      {
        name: "4",
        value: 4,
        checked: false,
        showNext: true,
        canContinue: false,
      },
      {
        name: "5",
        value: 5,
        checked: false,
        showNext: true,
        canContinue: false,
      },
      {
        name: "6",
        value: 6,
        checked: false,
        terminate: true,
        canContinue: true,
      },
      {
        name: "7",
        value: 7,
        checked: false,
        terminate: true,
        canContinue: true,
      },
      {
        name: "8",
        value: 8,
        checked: false,
        terminate: true,
        canContinue: true,
      },
      {
        name: "9",
        value: 9,
        checked: false,
        terminate: true,
        canContinue: true,
      },
      {
        name: "10",
        value: 10,
        checked: false,
        terminate: true,
        canContinue: true,
      },
    ],

    actionTowardsCrying: [
      {
        name: "No",
        value: "no",
        checked: false,
        showCrisis: true,
        canContinue: true,
      },
      {
        name: "Yes",
        value: "yes",
        checked: false,
        terminate: true,
        canContinue: true,
      },
    ],

    questionData: {
      harmingThemselves:
        "Some people who feel distressed sometimes report that they think about harming themselves or taking their own life. Have you ever felt like this?",
      attemptedPast: "Have you ever attempted anything like that in the past?",
      actThoughts:
        "How likely are you to act on these thoughts right now? (0 not at all, 10 will definitely act them straight away)",
      actionTowardsCrying:
        "Have you taken any action towards carrying out these plans?",
    },
  }),

  computed: {
    ...mapGetters(["lang"]),

    selectedHarmingThemselves() {
      return this.harmingThemselves.find((e) => e.checked);
    },

    selectedAttemptedPast() {
      return this.attemptedPast.find((e) => e.checked);
    },

    selectedActThoughts() {
      return this.actThoughts.find((e) => e.checked);
    },

    selectedActThoughtsValue() {
      return this.selectedActThoughts?.value || -1;
    },

    selectedActionTowardsCrying() {
      return this.actionTowardsCrying.find((e) => e.checked);
    },

    terminate() {
      return (
        this.selectedActThoughts?.terminate ||
        this.selectedActionTowardsCrying?.terminate
      );
    },

    showCrisis() {
      return (
        this.selectedActThoughts?.showCrisis ||
        this.selectedActionTowardsCrying?.showCrisis
      );
    },

    showAttemptedPast() {
      return this.selectedHarmingThemselves?.showNext;
    },

    showActThoughts() {
      return this.selectedAttemptedPast?.showNext;
    },

    showActionTowardsCrying() {
      return this.selectedActThoughts?.showNext;
    },

    canContinue() {
      return (
        this.selectedActionTowardsCrying ||
        this.selectedActThoughts?.canContinue ||
        this.selectedAttemptedPast?.canContinue ||
        this.selectedHarmingThemselves?.canContinue
      );
    },

    toTerminate() {
      return (
        this.selectedActionTowardsCrying?.terminate ||
        this.selectedActThoughts?.terminate
      );
    },

    toCrisis() {
      return (
        this.selectedActionTowardsCrying?.showCrisis ||
        this.selectedActThoughts?.showCrisis
      );
    },
  },

  methods: {
    ...mapMutations(["addNotification"]),
    ...mapActions("user", ["updateUserBriefAssessment"]),

    prevStep() {
      this.$emit("prevStep");
    },

    uncheckUnnecessarilyAnswers(objectName) {
      const mapper = [
        "harmingThemselves",
        "attemptedPast",
        "actThoughts",
        "actionTowardsCrying",
      ];

      const index = mapper.indexOf(objectName);

      if (!this[mapper[index]].find((e) => e.checked)?.showNext) {
        for (let i = index + 1; i < mapper.length; i++) {
          this[mapper[i]].forEach((e) => (e.checked = false));
        }
      }
    },

    toggleRadios(item, objectName) {
      this[objectName].map((e) => {
        if (e.name !== item.name) {
          e.checked = false;
        }
        item.checked = true;

        this.uncheckUnnecessarilyAnswers(objectName);
      });
    },

    continueDialog() {
      if (this.toTerminate) {
        this.$refs.terminateAccess.dialog = true;
      } else if (this.toCrisis) {
        this.$refs.crisisManagementRef.dialog = true;
      } else {
        this.nextStep();
      }
    },

    openTerms(cb) {
      if (this.lang === "ar") {
        this.myTherapyType === "Teen Therapy"
          ? this.$refs.termsTeenRef.open(cb)
          : this.$refs.termsArRef.open(cb);
      } else {
        this.myTherapyType === "Teen Therapy"
          ? this.$refs.termsTeenRef.open(cb)
          : this.$refs.termsRef.open(cb);
      }
    },

    async nextStep() {
      let briefAssessment = [];

      if (this.selectedHarmingThemselves) {
        briefAssessment.push({
          q: this.questionData["harmingThemselves"],
          a: this.selectedHarmingThemselves.value,
        });
      }

      if (this.selectedAttemptedPast) {
        briefAssessment.push({
          q: this.questionData["attemptedPast"],
          a: this.selectedAttemptedPast.value,
        });
      }

      if (this.selectedActThoughts) {
        briefAssessment.push({
          q: this.questionData["actThoughts"],
          a: this.selectedActThoughts.value,
        });
      }

      if (this.selectedActionTowardsCrying) {
        briefAssessment.push({
          q: this.questionData["actionTowardsCrying"],
          a: this.selectedActionTowardsCrying.value,
        });
      }

      try {
        await this.updateUserBriefAssessment(briefAssessment);
        this.openTerms(this.callbackAction);
        // this.$emit("nextStep");
      } catch (e) {
        this.addNotification({
          message: e.response.data.message,
          icon: "mdi-alert",
          timeout: 10000,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.v-btn {
  &.numbers {
    min-width: 50px !important;
    padding: 0 !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 10px !important;

    .v-btn__content {
      padding: 0;
    }
  }

  min-width: 150px !important;
  height: auto !important;

  &.muted--text {
    .v-btn__content {
      color: #000;
    }
  }

  .v-btn__content {
    position: unset;
    justify-content: center !important;
  }
}

::v-deep .v-btn__content {
  white-space: normal;
  flex-wrap: wrap;
  flex: auto;
  justify-content: start;
  padding: 10px;
}

::v-deep.v-btn {
  /* box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35); */
  border-radius: 15px !important;
}

::v-deep.v-btn--disabled {
  border-radius: 15px;
}

.right-aligned-checkbox {
  position: absolute;
  right: 11px;
  top: 11px;
}
.theme--light .hp-body-bg {
  background-position: top left, top right;
  /* background-attachment: fixed; */
  background-image: url("../../../assets/background-gradient-left.png"),
    url("../../../assets/background-gradient-right.png");
}
</style>
