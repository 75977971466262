<template>
  <div class="hp-body-bg d-flex flex-column" style="min-height: 100vh">
    <v-container>
      <v-row class="initial-assessment">
        <v-col
          cols="12"
          lg="9"
          xl="6"
          class="mx-auto"
          :class="[{ 'd-flex': stepperComplete }]"
        >
          <v-card class="rounded-lg">
            <v-toolbar color="primary" elevation="0" dark>
              <logoSvg />
            </v-toolbar>

            <v-card-text class="py-12 px-10" v-if="!stepperComplete">
              <ThirdStep
                ref="thirdStepRef"
                :userRegistrationInfo="userRegistrationInfo"
                @update:step="onBack"
                @update:thirdStep="updateThirdStep"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import logoSvg from "@/components/svg/Logo";
import ThirdStep from "@/views/noUser/InitialAssessment/ThirdStep";
import UserMixin from "@/mixins/UserMixin";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RetakeInitialAssessment",
  mixins: [UserMixin],

  components: { ThirdStep, logoSvg },

  data: () => {
    return {
      userRegistrationInfo: {
        userDisorder: {
          default: [],
          other: "",
        },
        clientPrefer: {
          therapistGender: "",
          therapistLanguage: "",
        },
      },
    };
  },

  computed: {
    ...mapGetters("disorder", ["disorders"]),
  },

  methods: {
    ...mapActions("disorder", {
      disorderGetAll: "getAll",
    }),
    ...mapActions("user", ["updateInitialAssessment"]),

    onBack() {
      this.$router.go(-1);
    },

    async updateThirdStep({ disorder }) {
      this.userRegistrationInfo.userDisorder = disorder;

      await this.updateInitialAssessment(this.userRegistrationInfo);

      await this.$router.push({ name: "ChangeTherapist" });
    },
  },

  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        if (this.me?.userDisorder) {
          this.userRegistrationInfo.userDisorder = JSON.parse(
            JSON.stringify(this.me.userDisorder)
          );
        }

        if (this.me?.clientPrefer) {
          this.userRegistrationInfo.clientPrefer = JSON.parse(
            JSON.stringify(this.me.clientPrefer)
          );
        }

        if (!this.disorders.length) {
          await this.disorderGetAll().catch((err) =>
            console.error("Error on Disorder get", err)
          );
        }

        this.$refs.thirdStepRef.fillDisordersList();
      },
    });
  },
};
</script>

<style scoped>
.theme--light .hp-body-bg {
  box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("../../../assets/main.png");
}
</style>
