<template>
  <div
    class="hp-body-bg not-logged-in d-flex flex-column"
    style="min-height: 100%"
  >
    <NoUserNavbar :back-button-action="backAction" />

    <v-container class="mt-lg-10 mt-3 my-lg-auto d-flex" justify-center>
      <v-container class="container-spacing">
        <v-row>
          <v-col cols="12" sm="9" class="mx-auto">
            <h6
              class="subtitle-1 font-weight-semibold mb-3 mt-lg-10 mt-6 mb-md-4"
            >
              {{ $t("WouldYouPreferYourTherapistToBe") }}
            </h6>
            <v-row class="align-stretch">
              <v-col
                cols="12"
                sm="auto"
                class="py-0"
                v-for="(gender, i) in genders"
                :key="i"
              >
                <v-btn
                  elevation="0"
                  :outlined="!gender.checked"
                  :color="!gender.checked ? 'muted' : 'primary'"
                  block
                  :ripple="false"
                  @click="toggleRadios(gender, 'genders')"
                  class="me-5 py-md-4 py-3 mt-3 mb-3"
                >
                  <div class="d-flex align-center">
                    <div class="right-aligned-checkbox">
                      <v-icon v-if="!gender.checked">mdi-circle-outline</v-icon>
                      <v-icon v-else>mdi-check-circle</v-icon>
                    </div>

                    <span>{{ $t(gender.name) }}</span>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
            <h6
              class="subtitle-1 font-weight-semibold mb-3 mt-lg-10 mt-6 mb-md-4"
            >
              {{ $t("WhatIsYourPreferredLanguage") }}
            </h6>
            <v-row class="align-stretch">
              <template v-for="(lang, i) in languages">
                <v-col
                  :key="i"
                  cols="12"
                  sm="auto"
                  class="py-0"
                  v-if="lang.name === 'English' || lang.name === 'Arabic'"
                >
                  <v-btn
                    elevation="0"
                    :outlined="!lang.checked"
                    :color="!lang.checked ? 'muted' : 'primary'"
                    block
                    :ripple="false"
                    @click="toggleRadios(lang, 'languages')"
                    class="me-5 py-md-4 py-3 mt-3 mb-3"
                  >
                    <div class="d-flex align-center">
                      <div class="right-aligned-checkbox">
                        <v-icon v-if="!lang.checked">mdi-circle-outline</v-icon>
                        <v-icon v-else>mdi-check-circle</v-icon>
                      </div>

                      <span>{{ $t(lang.name) }}</span>
                    </div>
                  </v-btn>
                </v-col>
              </template>

              <v-col class="py-0" cols="12" sm="">
                <v-select
                  hide-details
                  outlined
                  v-model="selectedLanguageValue"
                  :items="filteredLanguages"
                  item-text="name"
                  item-value="value"
                  :label="$t('Other Languages')"
                >
                  <template v-slot:append-outer
                    ><div class="right-aligned-checkbox">
                      <v-icon>mdi-check-circle</v-icon>
                    </div></template
                  >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn
                  color="primary"
                  class="px-5 py-1 mx-auto mt-8"
                  :disabled="canContinue"
                  @click="goNextStep"
                >
                  {{ $t("Continue") }}
                  <v-icon right> mdi-arrow-right </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <NoUserFooter />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UserMixin from "@/mixins/UserMixin";
import NoUserNavbar from "@/components/layout/NoUserNavbar";
import NoUserFooter from "@/components/layout/NoUserFooter";

export default {
  name: "FitAssessmentQuestions",
  components: { NoUserNavbar, NoUserFooter },

  mixins: [UserMixin],

  data: () => {
    return {
      selectedLanguageValue: "",
      genders: [
        { name: "Female", value: "female", checked: false },
        { name: "Male", value: "male", checked: false },
        {
          name: "No gender preference",
          value: "noGenderPreference",
          checked: false,
        },
      ],
      languages: [
        { name: "Arabic", value: "Arabic", checked: false },
        { name: "English", value: "English", checked: false },
        { name: "French", value: "French", checked: false },
        { name: "Spanish", value: "Spanish", checked: false },
        { name: "Italian", value: "Italian", checked: false },
        { name: "Greek", value: "Greek", checked: false },
        { name: "Hindi", value: "Hindi", checked: false },
        { name: "Urdu", value: "Urdu", checked: false },
        { name: "Malayalam", value: "Malayalam", checked: false },
        { name: "Russian", value: "Russian", checked: false },
      ],
    };
  },

  computed: {
    // ...mapState("preRegister", ["therapyType", "clientPrefer"]),

    filteredLanguages() {
      return this.languages.filter(
        (lang) => lang.name !== "Arabic" && lang.name !== "English"
      );
    },

    canContinue() {
      return !(this.selectedTherapistGender && this.selectedLanguage);
    },

    selectedTherapistGender() {
      return this.genders.find((e) => e.checked);
    },
    selectedLanguage() {
      return this.languages.find((e) => e.checked);
    },
  },

  watch: {
    selectedLanguageValue(newValue) {
      this.languages.forEach((lang) => {
        lang.checked = lang.value === newValue;
      });
    },
  },

  methods: {
    ...mapActions("user", ["updateInitialAssessment"]),

    handleSelection(selectedValue) {
      // Find the selected item based on the value
      const selectedItem = this.filteredLanguages.find(
        (lang) => lang.value === selectedValue
      );
      if (selectedItem) {
        this.toggleRadios(selectedItem, "languages");
      }
    },

    backAction() {
      if (this.me.therapyType === "Teen Therapy") {
        this.$router.push({
          name: "TeenTherapyReasonsRetake",
          query: this.$route.query,
        });
      } else {
        this.$router.push({
          name: "PrimaryReasonsRetake",
          query: this.$route.query,
        });
      }
    },

    toggleRadios(item, objectName) {
      if (!item.checked) {
        this[objectName].map((e) => {
          if (e.name !== item.name) {
            if (objectName === "languages") {
              this.selectedLanguageValue = item.name;
            }
            e.checked = false;
          }
          item.checked = true;
        });
      }
    },

    async goNextStep() {
      if (
        this.selectedTherapistGender !==
          this.me?.clientPrefer?.therapistGender ||
        this.selectedLanguage !== this.me?.clientPrefer?.therapistLanguage
      ) {
        await this.updateInitialAssessment({
          clientPrefer: {
            therapistGender: this.selectedTherapistGender.value,
            therapistLanguage: this.selectedLanguage.value,
          },
        });
      }

      if (this.$route.query?.next === "change-therapist") {
        await this.$router.push({ name: "ChangeTherapist" });
      } else {
        await this.$router.push({ name: "TherapistSelection" });
      }
    },
  },

  mounted() {
    if (this.me?.clientPrefer?.therapistGender) {
      let gender = this.genders.find(
        (e) => e.value === this.me?.clientPrefer?.therapistGender
      );
      gender.checked = true;
    }

    if (this.me?.clientPrefer?.therapistLanguage) {
      let language = this.languages.find(
        (e) => e.value === this.me?.clientPrefer?.therapistLanguage
      );
      language.checked = true;
    }
  },
};
</script>

<style scoped lang="scss">
.theme--light .hp-body-bg {
  background-position: top left, top right;
  /* background-attachment: fixed; */
  background-image: url("../../../../assets/background-gradient-left.png"),
    url("../../../../assets/background-gradient-right.png");
}

::v-deep.v-btn:not(.back-btn) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    min-width: 245px !important;
  }
  height: auto !important;

  &.muted--text {
    .v-btn__content {
      color: #000;
    }
  }

  .v-btn__content {
    position: unset;
    justify-content: center !important;
  }
}

::v-deep .v-btn__content {
  white-space: normal;
  flex-wrap: wrap;
  flex: auto;
  justify-content: start;
  padding: 10px;
}

::v-deep .v-select {
  .right-aligned-checkbox i {
    right: 11px;
    top: 11px;
    color: #fff !important;
  }
  .v-input__slot {
    height: 75px;
    border-radius: 15px !important;
    @media (min-width: 960px) {
      padding-top: 16px !important;
      padding-bottom: 16px !important;
      margin-bottom: 12px !important;
      margin-top: 12px !important;
    }
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    margin-bottom: 12px !important;
    margin-top: 12px !important;
  }
  .v-select__selections {
    left: 50% !important;
    position: absolute !important;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .v-input__append-inner {
    margin-top: 10px;
    margin-right: 20px;
  }
  &.v-input--is-label-active {
    .v-input__append-outer {
      margin-left: unset !important;
    }
    .v-select__selection--comma {
      overflow: visible;
    }
    .v-input__icon--append {
      display: none;
    }
    fieldset {
      background-color: #3475b2 !important;
    }
    .v-input__icon--append::before {
      color: #fff;
    }
    .v-label {
      display: none;
    }
    .v-input__slot {
      .v-select__selections {
        color: #fff;
      }

      fieldset legend {
        display: none;
      }
    }
  }
  &:not(.v-input--is-label-active) {
    .v-label {
      font-weight: bold;
      left: 50% !important;
      position: absolute !important;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

::v-deep.v-btn.v-btn--outlined:not(.v-btn--plain) {
  /* box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35); */
  border-radius: 15px !important;
}

::v-deep.v-btn--disabled {
  border-radius: 15px;
}

.right-aligned-checkbox {
  position: absolute;
  right: 11px;
  top: 11px;
}
</style>
