<template>
  <div>
    <p class="body-2 text-center">
      يُرجى عدم استخدام هذه الخدمة للاحتياجات الطبية الطارئة.
    </p>
    <p class="body-2 text-center">
      في حال واجه المستخدم حالة طبية طارئة، يُرجى الاتصال بالأرقام التالية:
    </p>
    <h4 class="text-center">
      بالنسبة لمن يقطنون دولة الإمارات العربية المتحدة:
    </h4>
    <p class="body-2 text-center">999 للشرطة: 998 لخدمات الإسعاف</p>
    <h4 class="text-center">
      وبالنسبة لمن يقطنون دولًا أخرى غير الإمارات العربية المتحدة:
    </h4>
    <p class="body-2 text-center">الخط الساخن المحلي للإسعاف أو الشرطة</p>
    <p class="body-2 text-center">
      يُرجى التوقف في الحال عن استخدام المنصة في حالات الطوارئ. فالمنصة غير
      مهيأة أو مُصممة لأن تُستخدَم في حالات الطوارئ. ولا يُمكن للشركة أو
      الاستشاريين تقديم مساعدة في حالات الطوارئ.
    </p>
    <p class="body-2 text-center">
      في حال اعتزم المستخدم الانتحار أو فكر في إيذاء نفسه أو الآخرين أو اعتقد أي
      مستخدم أن شخصًا آخر قد يكون في خطر، على المستخدم التوقف عن استخدام المنصة
      والتوجه في الحال للحصول على مساعدة طوارئ.
    </p>
    <p class="body-2 text-center">
      توك تايم (www.talktime.ae) هو منصة إلكترونية ("المنصة") تمتلكها شركة توك
      تايم لخدمات الصحة العقلية المحدودة، وهي شركة خاصة مسجلة في مركز دبي المالي
      العالمي ("المركز") برقم ترخيص CL5011 ("توك تايم" أو "الشركة"). يُرجى من
      المستخدم ("المُشار إليه فيما يلي باسم "المستخدم") قراءة الشروط والأحكام
      الواردة أدناه بعناية قبل استخدام المنصة. وباستخدام المنصة، يوافق المستخدم
      على الالتزام بالشروط والأحكام الواردة أدناه، كما أن الشروط والأحكام
      الواردة أدناه تشكل التزامًا قانونيًا على المستخدم ("شروط الاستخدام").
    </p>
    <p class="body-2 text-center">
      تهدف شروط المستخدم هذه إلى استخدامها من قبل من تتراوح أعمارهم بين 13 و 17
      عامًا، والذين يتم تسجيلهم على المنصة من قبل والديهم أو الأوصياء
      القانونيين. انت، المستخدم (المشار إليه فيما بعد بـ "المستخدم") يجب أن تشير
      إلى الطفل القاصر والوصي القانوني للطفل. لا يجوز لأي طفل قاصر استخدام
      المنصة أو التسجيل فيها ما لم يكمل الوصي القانوني نموذج موافقة الوالدين
      للشركة ويوقع عليه.
    </p>
    <p class="body-2 text-center">
      وبالموافقة على شروط الاستخدام هذه، يؤكد المستخدم على أنه الشخص المعرف في
      عملية التسجيل. كما يقر المستخدم بأن لديه الأهلية القانونية الكاملة
      للموافقة على شروط الاستخدام هذه ويوافق على الالتزام بالشروط والأحكام
      الواردة أدناه.
    </p>

    <h4>القسم الأول: الامتثال للقوانين الواجبة التطبيق وتطبيق القانون</h4>
    <p class="body-2">
      1-1- تتضمن موافقة الاستشاري على إبرام شروط الاستشارة هذه عن طريق استخدام
      المنصة موافقةً منه على أن شروط الاستشارة هذه تحكمها وتفسرها القوانين
      السارية في دولة الإمارات العربية المتحدة ومركز دبي المالي العالمي
      ("المركز") والتي تتضمن، على سبيل المثال لا الحصر: (1) دستور الإمارات
      العربية المتحدة (القانون الاتحادي رقم 1 لسنة 1971)؛ (2) قانون العقوبات
      (القانون الاتحادي رقم 3 لسنة 1987 وتعديلاته)؛ (3) قانون الجرائم
      الإلكترونية (القانون الاتحادي رقم 5 لسنة 2012 بشأن "مكافحة جرائم تقنية
      المعلومات" وتعديلاته بموجب القانون الاتحادي رقم 12 لسنة 2016 والقانون
      الاتحادي بالمرسوم رقم 2 لسنة 2018)؛ وقانون مركز دبي المالي العالمي رقم 5
      لسنة 2020 وتعديلاته بشأن معالجة البيانات الشخصية وحماية البيانات (يُشار
      إليها مجتمعين باسم "القوانين الواجبة التطبيق"، ومنفردين باسم "القانون
      الواجب التطبيق").
      <br /><br />
      1-2- تحتفظ الشركة بالحق في تعديل أو إنهاء أي جزء من شروط الاستخدام هذه
      بدون تقديم أي إخطار مسبق أو سبب.
      <br /><br />
      1-3- قد تضطر الشركة إلى الامتثال لطلبات هيئات تطبيق القانون أو أي هيئات
      حكومية أخرى بشأن معلومات تخص المستخدم. وسوف تزود الشركة المستخدم بإخطار عن
      تلك الطلبات متى كان ذلك ممكنًا.
    </p>

    <h4>القسم الثاني: الاستشاريون وخدمات المقدم</h4>

    <p class="body-2">
      2-1- المنصة مصممة للاستخدام عن طريق إنشاء حساب لدى الشركة لغرض توصيل
      المستخدم بالاستشاري أو مقدم الخدمات المشابه (يُشار إليهم منفردين باسم
      "الاستشاري"، ومجتمعين باسم "الاستشاريين"). سوف يقدم الاستشاريون خدمات
      الاستشارة المهنية أو الخدمات المشابهة إلى المستخدم حصريًا من خلال المنصة
      ("خدمات المقدم").
      <br /><br />
      2-2- تقدم الشركة، من خلال المنصة، مجموعة من الاستشارين يمكن للمستخدم
      التعامل معهم للحصول على خدمات المقدم. كما تقدم الشركة معلومات حول
      الاستشاريين على المنصة حتى يتسنى للمستخدم مراجعتها. ولكن لا تبذل الشركة
      بالضرورة العناية اللازمة حول دقة كل المعلومات المتوفرة على صفحات
      الاستشاريين التي يمكن الوصول إليها على المنصة. ويكون المستخدم مسؤولًا عن
      قراراته الخاصة حول الاستشاريين الذين يسعى المستخدم الحصول على الخدمات
      منهم. كما يكون المستخدم مسؤولًا عن قراراته الخاصة حول طريقة التواصل التي
      يختارها عند الحصول على خدمات المقدم (إذ يحق للمستخدمين الاختيار بين
      التواصل مع الاستشاريين عن طريق: (1) المراسلة الفورية عبر الإنترنت، أو (2)
      الاجتماع عن بعد، أو (2) الاستشارات عبر الفيديو). كما يوافق المستخدم على أن
      الشركة غير مسؤولة عن أي عمل أو إهمال يتم من خلال الاستشاريين أثناء
      الاستشارة.
      <br /><br />
      2-3- يوافق العميل على ألَّا يسجل أي جلسة أو محتوى آخر ولا يعيد بيعها ولا
      تشكيلها بدون الحصول على موافقة مكتوبة مسبقة من الشركة.
      <br /><br />
      2-4- لا تستضيف الشركة خدمات تحتاج من المهنيين الحصول على رخصة طبية سارية.
      ولا تقدم الشركة المشورات الطبية. وتعتبر أي ستشارات طبية أو إقرارات متعلقة
      بالشؤون الطبية تصدر من الاستشاريين خرقًا للشروط والأحكام الخاصة
      بالاستشارات، ويكون الاستشاري وحده مسؤولًا عن كل ما قد ينشأ عن ذلك الخرق من
      نتائج أو مسؤوليات.
      <br /><br />
      2-5- وعند استخدام المنصة، يتعامل المستخدم مع الاستشاري على مسؤوليته
      الخاصة. ولا تكون الشركة مسؤولة عن أي أذى أو ضرر ينشأ عن التعامل بين
      المستخدم وأي استشاري. وتخلي الشركة مسؤوليتها عن أي مسؤولية مدنية أو جنائية
      قد تنشأ عن سوء الفهم أو المعلومات الخاطئة التي تنشأ عن التواصل بين أي
      استشاري وأي مستخدم.
      <br /><br />
      2-6- ويكون أي استشاري يتواصل مع المستخدم من خلال استخدام المنصة مقاولًا
      مستقلًا عن الشركة وليس لديه الصلاحية لإلزام الشركة أو التحدث نيابةً عنها
      بأي حالٍ من الأحوال. ودور الشركة محدود بتقديم التقنيات التي تمكن من تقديم
      الخدمات إلى المستخدم من خلال الاستشاريين، ويتحمل الاستشاريون المسؤولية
      الحصرية عن تقديم خدمات المقدم. والشركة غير ملزمة بالإشراف على أي خدمات
      تُقدَّم إلى المستخدمين أو مراقبتها.
      <br /><br />
      2-7- يفهم المستخدم ويقر ويوافق على أن خدمات المقدم قد لا تكون حلًا مناسبات
      لاحتياجات المستخدم. كما يقر المستخدم ويوافق على أن الخدمات ليست بديلًا عن
      الخدمات المهنية التي تُقدَّم بشكل شخصي. إن رأى المستخدم أن الخدمات لا
      تتوافق مع احتياجاته أو تطلعاته، على المستخدم أن يختار إما تغيير الاستشاري
      أو التوقف عن استخدام المنصة.
      <br /><br />
      2-8- وبإنشاء حساب، يوافق المستخدم على استلام مراسلات من الشركة من وقتٍ إلى
      آخر، مثل رسائل إلكترونية ونصية وتذكيرية وإخبارية وتحديثات أخرى. ويمكن
      للمستخدم إلغاء الاشتراك في تلك المراسلات عن طريق إرسال رسالة إلكترونية إلى
      الشركة.
      <br /><br />
      2-9- لا تدعم الشركة أي علامة تجارية ولا تتولى الإعلان نيابةً عن أي شركة أو
      عيادة أو مؤسسة أخرى يمتلكها أو يديرها أو يدعمها أي استشاري.
      <br /><br />
      2-10- يوافق المستخدم ويفهم أن الاستشاري، بحسب رأيه المهني، قد يطلب التواصل
      مع واحد أو أكثر من جهات اتصال الطوارئ لدى المستخدم. في حال عدم تقديم
      المستخدم معلومات جهات اتصال الطوارئ لديه أو رفضه السماح للاستشاري بالتواصل
      معهم، يجب أن يتواصل المستخدم في الحال مع الخط الساخن لخدمات الإسعاف أو
      الطوارئ المحلية لديه وسيتم إيقاف خدماته وحسابه لدى الشركة في الحال.
      <br /><br />
      2-11- يوافق المستخدم ويفهم أن الاستشاري لا يحمل بالضرورة رخصة مزاولة
      العلاج النفسي حيث يقيم المستخدم.
    </p>
    <h4>القسم الثالث: حجز الجلسات والدفع</h4>
    <p class="body-2">
      3-1- يجب أن يحدد المستخدمون مواعيد للحصول على خدمات المقدم من خلال المنصة.
      وللحصول على الخدمات، يلتزم المستخدم بأن يدفع رسوم اشتراك تغطي الخدمات لمدة
      لا تقل عن أسبوعين. ويتم تقديم كل مدفوعات تحديد مواعيد خدمات المسجل أو
      الحصول عليها حصريًا من خلال المنصة. كما يجب على المستخدم تحويل مدفوعات
      خدمات المقدم قبل الحصول عليها.
      <br /><br />
      3-2- تشترط المنصة على المستخدمين تقديم عناوين البريد الإلكتروني وأسماء
      المستخدم وكلمات السر الخاصة بهم فقط عند تسجيل الحساب. ولن تفصح الشركة عن
      تلك المعلومات إلى الغير خارج نطاق المنصة ما لم تضطر الشركة إلى ذلك من خلال
      هيئات تطبيق القانون أو الهيئات الحكومية أو بموجب حكم محكمة.
      <br /><br />
      3-3- بتقديم طريقة دفع مقبولة للشركة، يخول المستخدم الشركة تحميل الفواتير
      على تلك الطريقة مقابل أي مبالغ تستحق على المستخدم إلى الشركة.
      <br /><br />
      3-4- عند تحويل المدفوعات، يقر المستخدم بأهليته الكاملة لتقديم تلك
      المدفوعات. كما يتحمل المستخدم المسؤولية الكاملة عن الادعاءات الكاذبة بشأن
      أهليته لتقديم الدفع بطرق الدفع التي يستخدمها على المنصة.
      <br /><br />
      3-5- يوافق المستخدم على أنه لا يجوز رد أي مدفوعات ما لم تنص الشركة على غير
      ذلك كتابةً.
      <br /><br />
      3-6- في حال تعطل المنصة وعدم إمكانية تقديم خدمات المقدم، يحق للمستخدم
      إخطار الشركة بتلك الظروف الخاصة عن طريق البريد الإلكتروني للمطالبة
      باسترداد المبلغ. وبعد استلام ذك الإخطار، ستراجع الشركة الظروف وترد المبلغ
      إلى المستخدم إن رأت الشركة بتقديرها المطلق أن المستخدم يستحق استرداد
      المبلغ.
      <br /><br />
      3-7- يوافق المستخدم على أنه لا يتم رد أي مبالغ في حال خرق المستخدم لأي من
      واجباته أو التزاماته بموجب شروط الاستخدام هذه، أو بتقديم أي إقرارات هامة
      خاطئة بموجب شروط الاستخدام هذه، أو خرق أي قانون واجب التطبيق في إطار
      استخدام المنصة.
      <br /><br />
      3-8- تحتفظ الشركة بالحق في تعديل أو إنهاء أو إيقاف أي مستخدم أو طريقة دفع
      بحسب تقديرها المطلق.
      <br /><br />
      3-9- يحق للمستخدم إلغاء أي جلسة أو تغيير موعدها بتقديم إخطار مكتوب مسبق
      بمدة أكثر من 12 ساعة. في حال عدم حضور المستخدم أي جلسة بدون تقديم إخطار
      مكتوب مسبق بمدة أكثر من 12 ساعة، يعتبر المستخدم قد حضر تلك الجلسة.
    </p>
    <h4>القسم الرابع: الملكية الفكرية والغير</h4>
    <p class="body-2">
      4-1- الشركة تمتلك كل حقوق الملكية الفكرية بشأن التقنيات التي تشكل المنصة
      نفسها أو هي الجهة المرخصة لها تلك الحقوق.
      <br /><br />
      4-2- المواد المنشورة على المنصة محمية بقوانين حقوق الطبع والنشر الواجبة
      التطبيق. ولا يحق للمستخدم تحميل أو طباعة أو استخدام أو تعديل أي مواد متاحة
      على المنصة لأي غرض غير الاستخدام الشخصي. في حال قام المستخدم بتحميل أو
      طباعة أو استخدام أو تعديل المواد المتاحة على المنصة لاستخدامه الشخصي،
      يلتزم المستخدم بتدمير كل تلك المواد في الحال بمجرد الانتهاء من ذلك
      الاستخدام الشخصي.
      <br /><br />
      4-3- لا يحق للمستخدم استخدام المنصة لخرق أي قوانين.
      <br /><br />
      4-4- لا يحق للمستخدم محاولة اختراق أو تفكيك أو القيام بالهندسة العكسية على
      أي برمجيات تشكل المنصة.
      <br /><br />
      4-5- قد تحتوي المنصة على محتوى أو منتجات أو خدمات مرتبطة بمواقع إلكترونية
      أخرى تعرض محتوى أو منتجات أو خدمات أو إعلانات ("محتوى الغير"). ولا تتحمل
      الشركة أي مسؤولية عن المحتوى المعين الذي يقدمه محتوى الغير، ولا تكون
      الشركة مسؤولة عن أي ضرر أو خسارة قد تنشأ عن انخراط المستخدم في محتوى
      الغير.
      <br /><br />
      4-6- يحق للشركة تطوير وعرض تحديثات البرمجيات بشأن المنصة من وقت إلى آخر
      ("تحديثات المنتجات"). وقد يتطلب من المستخدم تحميل نسخة محدثة من المنصة
      لاستمرار الوصول إلى خدمات المقدم. ولا تضمن الشركة العمل المناسب لأي نسخ
      تالية للمنصة على أجهزة المستخدم (بما فيها أجهزة الحاسوب الشخصية،
      واللابتوب، والهواتف المحمولة). ولا تكون الشركة مسؤولة عن أي خسارة يتكبدها
      المستخدم بسبب تحديثات الخدمات.
    </p>
    <h4>القسم الخامس: إخلاء مسؤولية عن الضمانات وحدود المسؤولية</h4>
    <p class="body-2">
      5-1- يُخلي المستخدم الشركة ورؤسائها ومديريها وموظفيها وأعضائها والمتطوعين
      بها ومقاوليها وممثليها وشركاتها الأم ومُلَّاكها وشركاتها التابعة ووكلائها
      وخلفها العام والخاص الحاليين والمستقبليين (يُشار إليهم مجتمعين باسم
      "الأطراف المعنية بالشركة") ويوافق على حماية الأطراف المعنية بالشركة من أي
      تبعات لإجراءات أو دعاوى أيًا كانت طبيعتها والتي تنتج عن خدمات المقدم أو
      المنصة، بما فيها (على سبيل المثال لا الحصر) أي عمل أو إهمال أو رأي أو رد
      أو مشورة أو اقتراح أو معلومات أو خدمة من أي استشاري أو أي محتوى آخر أو
      معلومات يمكن الوصول إليها من خلال المنصة.
      <br /><br />
      5-2- يفهم المستخدم ويوافق ويقر بأن المنصة مقدمة "على حالتها"، بدون أي
      ضمانات صريحة أو ضمنية من أي نوع (بما فيها على سبيل المثال لا الحصر، صلاحية
      العرض في السوق، أو عدم الانتهاك، أو الأمان، أو الملاءمة لغرض معين، أو
      الدقة). ويكون استخدام المنصة على مسؤولية المستخدم. وإلى أقصى حد يسمح به
      القانون، تخلي الشركة مسؤوليتها صراحةً عن أي ضمانات أيًا كان نوعها، الصريح
      منها والضمني.
      <br /><br />
      5-3- ويفهم المستخدم ويوافق ويقر بأنه لا تكون الشركة أو أي من شركاتها
      التابعة مسؤولةً أمام المستخدم أو الغير عن أي أضرار غير مباشرة أو عرضية أو
      تبعية أو خاصة أو تأديبية أو نموذجية.
      <br /><br />
      5-4- في حال عدم سماح القانون الواجب التطبيق بحدود المسؤولية على النحو
      الوارد أعلاه، تعتبر الحدود مُعدَّلَة فقط إلى المدى اللازم للامتثال للقانون
      الواجب التطبيق. وتظل حدود المسؤولية الواردة في هذه الصفحة سارية بعد إنهاء
      شروط الاستخدام هذه أو انتهاءها.
    </p>
    <h4>القسم السادس: إقرارات المستخدم وضماناته</h4>
    <p class="body-2">
      6-1- يقر المستخدم بأنه مؤهل قانونًا لإبرام والامتثال لشروط الاستخدام هذه.
      <br /><br />
      6-2- يقر المستخدم بأنه مؤهل قانونًا للموافقة على الحصول على خدمات المقدم.
      <br /><br />
      6-3- يقر المستخدم بأن كل المعلومات التي يقدمها المستخدم على المنصة، حاليًا
      أو مستقبليًا، كاملة ودقيقة وحقيقية. وبالإضافة إلى ذلك، يوافق المستخدم على
      أنه أثناء مدة شروط الاستخدام هذه، سيتخذ المستخدم كافة الإجراءات المعقولة
      للحفاظ على معلومات المستخدم وتحديثها لضمان استمرار كمال كل تلك المعلومات
      ودقتها وصحتها.
      <br /><br />
      6-4- يكون المستخدم مسؤولًا عن حماية سرية كلمة السر الخاصة به. ويُرجى من
      المستخدم تغيير كلمة السر الخاصة به باستمرار وأن يبذل عناية إضافية لحماية
      حسابه.
      <br /><br />
      6-5- يخطر المستخدم الشركة في الحال عند حدوث أي استخدام غير مخول به لحسابه،
      أو في حال قلق المستخدم من أن حسابه قد استخدمه الغير.
      <br /><br />
      6-6- يوافق المستخدم على ألَّا يستخدم المنصة إلَّا لغرض الحصول على خدمات
      المقدم. ولا يحق للمستخدم استخدام المنصة إلَّا لاستخدامه الشخصي؛ ولا يحق
      للمستخدم استخدام المنصة نيابةً عن أي شخص أو مؤسسة أو منظمة أخرى.
      <br /><br />
      6-7- يقر المستخدم ويوافق على أن الشركة غير مسؤولة عن أي خسارة أو ضرر يحدث
      نتيجةً لأي وصول غير مخول به لحساب المستخدم، سواءً كان ذلك بموافقة وعلم
      المستخدم أو بدون موافقته وعلمه.
      <br /><br />
      6-8- يقر المستخدم ويوافق على أنه مسؤول مسؤولية حصرية عن كل الأنشطة التي
      تُمارَس باستخدام حسابه. كما يقر المستخدم ويوافق على أنه يحق للشركة تحميل
      المستخدم المسؤولية عن أي أضرار أو خسائر تحدث نتيجةً لاستخدام حساب المستخدم
      من قبل الغير، سواءً كان ذلك الاستخدام مخولًا به من جانب المستخدم أو لا،
      ويلتزم المستخدم بتعويض الشركة عن كل الأضرار والخسائر ذات الصلة.
      <br /><br />
      6-9- يلتزم المستخدم بتعويض الشركة والدفاع عنها وحمايتها من كل الدعاوى
      والخسائر ونتائج الإجراءات والمطالبات والمسؤوليات والتكاليف والمصاريف (بما
      فيها على سبيل المثال لا الحصر التقاضي وأتعاب المحاماة المعقولة والمصاريف)
      والتي تنشأ عن أي ممَّا يلي: (1) وصول المستخدم إلى المنصة أو استخدامه لها؛
      (2) أي إجراءات تتم من خلال حساب المستخدم سواءً قام بها المستخدم أو
      الغير؛(3) خرق المستخدم لأيٍ من أحكام شروط الاستخدام هذه؛ (4) عدم دفع مقابل
      أي من الخدمات (بما فيها خدمات المقدم) والتي تقدم من خلال المنصة؛ (5) خرق
      المستخدم لأيٍ من حقوق الغير، بما فيها على سبيل المثال لا الحصر حقوق
      الملكية الفكرية، أو الدعاية، أو السرية، أو حق الملكية أو الخصوصية. ويظل
      هذا البند ساريًا بعد انتهاء شروط الاستخدام هذه أو إنهاءها.
      <br /><br />
      6-10- لا يحق للمستخدم استخدام أي حساب آخر على المنصة لأي سببٍ من الأسباب.
      ولا يجوز للمستخدم الوصول إلا لحساب واحد (1) فقط.
      <br /><br />
      6-11- لا يتدخل المستخدم في أي من أنظمة الشركة أو خدماتها أو خوادمها أو
      شبكاتها أو بنيتها التحتية (بما فيها المنصة ومكونات البرمجيات الخاصة بها)
      ولا يحاول التدخل فيها.
      <br /><br />
      6-12- لا يحق للمستخدم: (1) إرسال رسائل غير مرغوب فيها نصية أو عبر البريد
      الإلكتروني على المنصة من شأنها الترويج لأي بضائع أو خدمات أو الإعلان عنها؛
      (2) التدخل في برمجيات الشركة أو أنظمتها أو الإضرار بها من خلال استخدام
      برمجيات ضارة (تُسمَّى أيضًا "البرمجيات الخبيثة")، أو أكواد أو فيروسات
      الحاسوب؛ (3) الانخراط في أي سلوك غير شرعي أو مزعج أو عدواني أو تعسفي أو
      تهديدي أو مبتذل أو بذئ أو عنصري أو من المحتمل أن يسبب ضررًا للآخرين الذين
      قد يستخدمون المنصة (بما فيها الاستشاريين والمستخدمين الآخرين)؛ (4) نشر أو
      عرض محتوى يخرق حقوق الملكية الفكرية للغير؛ (5) نشر أو عرض محتوى قد يضر
      بالآخرين أو يؤذيهم؛ (6) نشر أو عرض أي محتوى قد يشكل جريمة أو إهمال أو خرق
      لأي قانون أ و لائحة واجبة التطبيق أو من شأنه تقريبًا أن يتسبب في ذلك أو
      يشجع عليه؛ (7) السعي للحصول على معلومات شخصية أو متعلقة بحسابٍ ما من
      الاستشاريين أو المستخدمين الآخرين للمنصة.
      <br /><br />
      6-13- عند استخدام المنصة، لا ينخرط المستخدم في أي عمل من شأنه أن يخرق أي
      قانون أو تشريع أو مرسوم أو قاعدة أو لائحة أو نظام أخلاقي محلي أو دولي واجب
      التطبيق.
      <br /><br />
      6-14- يتم أداء كل التعاملات والاتصالات بين المستخدم وأي استشاري حصريًا من
      خلال المنصة. ولا يحق للمستخدم التواصل مع الاستشاريين أو التعامل معهم خارج
      المنصة بدون الحصول على موافقة مكتوب مسبقة من الشركة لمدة سنة ميلادية واحدة
      (1) على الأقل من تاريخ جلسته الأخيرة مع أي استشاري على المنصة.
      <br /><br />
      6-15- في كل الاتصالات الإلكترونية مع الشركة أو الاستشاريين، يوافق المستخدم
      على فحص وتدقيق كل المرفقات والملفات ضد الفيروسات والبرمجيات الخبيثة
      الأخرى.
    </p>

    <h4>القسم السابع: انقطاعات المنصة وتعديلاتها وإنهاؤها</h4>
    <p class="body-2">
      7-1- يحق للشركة تعديل أو إيقاف أو تعطيل أو حجب المنصة أو أي جزء منها أو
      القدرة على استخدامها في أي وقت وبدون الحاجة لتقديم إخطار بذلك. كما يحق
      للشركة إيقاف حساب المستخدم أو إنهاؤه بحسب تقديرها المطلق. ولا تكون الشركة
      مسؤولة عن أي خسائر أو أضرار قد تنشأ عن أي من الأعمال السالفة الذكر.
      <br /><br />
      7-2- يفهم المستخدم ويقر بأن عمل المنصة يعتمد جزئيًا على أداء مقدمي خدمات
      الإنترنت الآخرين ومكونات البرمجيات لديهم وأدواتهم وملحقاتهم والتي قد لا
      تخضع لتحكم الشركة. وعلى الرغم من أن الشركة تبذل الجهود المعقولة تجاريًا
      لضمان موثوقية المنصة وإمكانية الوصول إليها، فلا تضمن الشركة إمكانية الوصول
      إلى المنصة وتوافقها مواقيتها وخلوها من الأخطاء طوال الوقت. وبناءً على ذلك،
      فلا تكون الشركة مسؤولة عن أي انقطاعات أو إبطاء أو أو فصل يمكن أن يحدث
      أثناء وصول المستخدم إلى المنصة أو حصوله على خدمات المقدم.
    </p>
    <h4>القسم الثامن: التحكيم الإلزامي</h4>

    <p class="body-2">
      تتم تسوية كل المنازعات التي تنشأ عن شروط الاستخدام هذه أو فيما يتعلق بها
      نهائيًا بموجب قواعد التحكيم الخاصة بغرفة التجارة الدولية ("القواعد") بمحكم
      فرد يتم تعيينه وفقًا للقواعد المذكورة. ويوافق الطرفان صراحةً وفقًا للمادة
      30(2)(ب) من القواعد على تطبيق قواعد الإجراءات المعجلة بغض النظر عن المبلغ
      المتنازع عليه. ويكون محل التحكيم هو إمارة دبي بدولة الإمارات العربية
      المتحدة. وتكون لغته هو الإنجليزية.
    </p>

    <h4>القسم التاسع: الإخطارات</h4>
    <p class="body-2">
      9-1- المنصة غير مصممة من أجل تقديم تشخيصات سريرية تحتاج إلى تقييم شخصي.
      كما أنها غير مصممة لغرض التشخيص أو العلاج بوصف استخدام العقاقير أو العلاج
      الطبي، وعلى المستخدم أن يتجاهل أي من تلك المشورات في حال تقديمها من خلال
      المنصة.
      <br /><br />
      9-2- يُرجى من المستخدمين عدم تجاهل أو تجنب أو تأخير الحصول على الرعاية
      الشخصية من أطبائهم أو غيرهم من المهنيين المؤهلين بسبب معلومات أو مشورة حصل
      عليها المستخدم من خلال المنصة.
    </p>
  </div>
</template>

<script>
export default {
  name: "TeenTermsTextArabic",
};
</script>

<style scoped></style>
