<template>
  <v-dialog
      persistent
      scrollable
      v-model="dialog"
      class="mb-10"
      max-width="700px"
  >
    <v-card class="rounded-card-with-shadow">
      <!-- <v-toolbar color="primary" elevation="0" dark>
        <logoSvg/>
      </v-toolbar> -->
      <v-card-title class="justify-center">
        <h2 class="mb-5 black--text headline font-weight-bold">
          {{ $t("Terms of Use") }}
        </h2>
        <!-- <h6
          class="body-2 mb-5 font-weight-bold title w-100 text-center warning--text"
        >
          <v-icon color="warning">mdi-alert </v-icon>
          {{ $t("You must read and scroll down to accept") }}
        </h6> -->
      </v-card-title>
      <v-card-text class="px-10 text-center">
        <p class="body-2">
          DO NOT USE THIS SERVICE FOR EMERGENCY MEDICAL NEEDS. IF USER
          EXPERIENCES A MEDICAL EMERGENCY PLEASE CALL:
        </p>
        <h4 class="mt-5 mb-5">
          <strong>FOR THOSE RESIDING IN THE UAE:</strong>
        </h4>
        <p class="body-2">999 FOR THE POLICE; 998 FOR AMBULATORY SERVICES</p>
        <h4 class="mt-5 mb-5">
          <strong>FOR THOSE RESIDING IN COUNTRIES OTHER THAN THE UAE:</strong>
        </h4>
        <p class="body-2">LOCAL AMBULANCE OR POLICE HOTLINE</p>
        <p class="body-2 text-left">
          IMMEDIATELY STOP USING THE PLATFORM IN CASES OF EMERGENCIES. THE
          PLATFORM IS NOT DESIGNED FOR, NOR IS IT INTENDED TO BE USED IN,
          EMERGENCY SITUATIONS. NEITHER THE COMPANY NOR THE COUNSELORS CAN
          PROVIDE EMERGENCY ASSISTANCE.
        </p>
        <p class="body-2 text-left">
          IF A USER IS CONTEMPLATING SUICIDE OR IF A USER IS CONSIDERING HARMING
          HIMSELF, HERSELF, OR OTHERS, OR IF USER FEELS THAT ANY OTHER PERSON
          MAY BE IN ANY DANGER, USER MUST STOP USING THE PLATFORM AND MUST
          IMMEDIATELY SEEK EMERGENCY ASSISTANCE.
        </p>
        <p class="body-2 text-left">
          Talk Time (www.talktime.ae) is a website platform (the “Platform“)
          owned and maintained by TalkTime Mental Health Services Limited, a
          Private Company registered in the Dubai International Financial Center
          (“DIFC“) with License Number CL5011 (“Talk Time” or the “Company“).
          You, the user (hereafter the “User“) must carefully read the terms and
          conditions contained herein before using the Platform. By using the
          Platform the User agrees to abide by the terms and conditions
          contained herein, and the terms and conditions contained herein
          constitute a binding legal obligation on the User (the “User Terms“).
        </p>

        <p class="body-2 text-left">
          By accepting these User Terms, User affirms that he or she is the
          person identified in the registration process. User represents that he
          or she has full legal authority to enter into these User Terms and
          agrees to be bound by the terms and conditions set forth herein.
        </p>

        <p class="body-2 text-left">
          The Platform is not to be used by minors (persons under the age of
          18). Use of the Platform constitutes a confirmation by the User that
          the User is 18 years of age or older. If the User does not agree to be
          bound to any term of these User Terms, or if the User is a minor, the
          User is not authorized to use the Platform. The Company will bear no
          liability whatsoever arising out of any unauthorized use.
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong>
            SECTION 1: COMPLIANCE WITH APPLICABLE LAWS AND LAW ENFORCEMENT
          </strong>
        </h4>

        <p class="body-2 text-left">
          1.1 The Counselor’s consent to enter into these Counselor Terms by
          using the Platform includes the Counselor’s consent that these
          Counselor Terms shall be governed by and construed in accordance with
          with all Applicable Laws of the United Arab Emirates and the Dubai
          International Financial Center (the “DIFC“) which may include, without
          limitation: (1) the Constitution of the United Arab Emirates (Federal
          Law 1 of 1971); (2) the Penal Code (Federal Law 3 of 1987 as amended);
          (3) the Cyber Crime Law (Federal Law No. 5 of 2012 regarding
          “Information Technology Crime Control”, as amended by Federal Law No.
          12 of 2016 and Federal Law by Decree No. 2 of 2018); and the DIFC Law
          No. 5 of 2020, as amended, regarding personal data processing and data
          protection (collectively the “Applicable Laws“, each being an
          “Applicable Law“).
          <br/><br/>
          1.2 The Company reserves the right to amend or terminate any portion
          of these User Terms without prior notice and for any reason.
          <br/><br/>
          1.3 The Company may be obligated to comply with requests from law
          enforcement and other governmental authorities for information about
          the User. The Company will provide the User with notice of these
          requests wherever possible.
        </p>
        <h4 class="mt-5 mb-5 text-left">
          <strong> SECTION 2: COUNSELORS AND THE PROVIDER SERVICES</strong>
        </h4>

        <p class="body-2 text-left">
          2.1 The Platform is designed to be used by creating an account with
          the Company for purposes of connecting the User with a counselor or a
          similar service provider (each a “Counselor” and collectively the
          “Counselors“). Counselors will deliver professional counseling or
          similar services to the User exclusively through the Platform (the
          “Provider Services“).
          <br/><br/>
          2.2 The Company provides, through the Platform, a panel of Counselors
          with whom the User can interact for purposes of seeking and receiving
          Provider Services. The Company provides information about the
          Counselors on the Platform for the User to review. However, the
          Company does not necessarily conduct due diligence on the accuracy of
          all information included on Counselor profiles that are accessible on
          the Platform. The User is responsible for making independent decisions
          about Counselors the User seeks Provider Services from. The User is
          also responsible for making independent decisions about which mode or
          modes of communication to use when receiving Provider Services (Users
          may choose to communicate with Counselors with: (1) real-time online
          messaging; (2) teleconferences; or (3) video consultations). The User
          agrees that the Company will not be responsible for any act or
          omission made by Counselors during any consultation.
          <br/><br/>
          2.3 The User agrees not to record, resell or recreate any session or
          other content without the Company’s prior, written consent.
          <br/><br/>
          2.4 The Company does not host services that require professionals to
          hold active medical licenses. The Company does not provide medical
          advice. Any medical advice or medical-related representations provided
          by Counselors amount to a breach of the Counselor’s terms and
          conditions, for which the Counselor shall bear exclusive
          responsibility and be subject to any and all liabilities that may
          arise from such a breach.
          <br/><br/>
          2.5 Whenever using the Platform, the User shall interact with the
          Counselor at the User’s own risk. The Company will not be liable for
          any harm or damages arising out of interactions between the User and
          any Counselor. The Company specifically disclaims any responsibility
          or liability that may arise from any miscommunication or
          misinformation arising from communications between any Counselor and
          any User.
          <br/><br/>
          2.6 Any Counselor that the User is connected with through the use of
          the Platform is an independent contractor of the Company who does not
          have authority to bind or speak on behalf of the Company in any way.
          The Company’s role is limited to providing technologies that enable
          the Provider Services to be provided to the User by Counselors, and
          Counselors assume exclusive responsibility for the delivery of the
          Provider Services. The Company has no obligation to supervise or
          monitor any of the Provider Services provided to Users.
          <br/><br/>
          2.7 The User understands, acknowledges, and agrees that Provider
          Services may not be an appropriate solution for the User’s needs. The
          User also acknowledges and agrees that the Provider Services are not a
          substitute for professional services that are delivered on an
          in-person basis. If the User believes that the Provider Services do
          not meet User’s needs or expectations, the User must either select a
          different Counselor or cease using the Platform.
          <br/><br/>
          2.8 By creating an account, User agrees to receive communications from
          the Company from time to time, such as e-mails, text messages,
          reminders, newsletters, and other updates. Users may opt-out of these
          communications by sending an e-mail to the Company.
          <br/><br/>
          2.9 The Company does not endorse any brand or advertise on behalf of
          any business, clinic, or other institution that may be owned,
          operated, or supported by any Counselor.
          <br/><br/>
          2.10 The User agrees and understands that the Counselor may, based on
          their professional opinion, request to contact one or more of the
          User’s emergency contacts. In the event that a User fails to provide
          their emergency contact information and/or refuses to allow the
          Counselor to contact such emergency contacts, then the User must
          immediately contact their local ambulance or emergency hotline and
          their service and account with the Company will be immediately
          suspended.
        </p>
        <h4 class="mt-5 mb-5 text-left">
          <strong> SECTION 3: BOOKING SESSIONS AND MAKING PAYMENTS</strong>
        </h4>
        <p class="body-2 text-left">
          3.1 Users must schedule times to receive Provider Services through the
          Platform. In order to receive Provider Services, the User must pay a
          subscription fee covering Provider Services for a minimum of two (2)
          weeks. All payments made in order to schedule and receive Subscriber
          Services shall be made exclusively through the Platform. The User will
          also be required to remit payment for Provider Services in advance of
          receiving Provider Services.
          <br/><br/>
          3.2 The Platform requires Users to provide their email address,
          username, and password only when registering for an account. The
          Company will not disclose this information to parties outside the
          Platform unless the Company is compelled to do so by law enforcement,
          government agencies, or by court order.
          <br/><br/>
          3.3 By providing the Company with an acceptable form of payment, the
          User authorizes the Company to bill that form of payment for any
          amounts the User owes to the Company.
          <br/><br/>
          3.4 Whenever remitting payment, the User represents that the User is
          fully authorized to make such payment. User assumes all liability for
          misrepresentations regarding its authority to make payments with forms
          of payment it uses on the Platform.
          <br/><br/>
          3.5 The User agrees that all payments shall be nonrefundable, unless
          otherwise provided for by the Company in writing.
          <br/><br/>
          3.6 If the Platform malfunctions such that Provider Services cannot be
          provided, the User may notify the Company of the specific
          circumstances by way of email to request a refund. After receiving
          such notification, the Company will review the circumstances and
          provide a refund to the User if, in the Company’s sole discretion, it
          has determined that the User is entitled to the requested refund.
          <br/><br/>
          3.7 The User agrees that no payment will be refunded in the event that
          the User has breached any of its duties or obligations under these
          User Terms, made material misrepresentations under these User Terms,
          or has violated any Applicable Law while using the Platform.
          <br/><br/>
          3.8 The Company reserves the right to modify, terminate, or suspend
          any User or form of payment at its sole discretion.
          <br/><br/>
          3.9 The User may cancel or reschedule any session with more than
          12-hours advance written notice. In the event the User fails to attend
          any session without providing 12-hour advance written notice, such
          session shall be deemed attended by the User.
        </p>
        <h4 class="mt-5 mb-5 text-left">
          <strong> SECTION 4: INTELLECTUAL PROPERTY AND THIRD PARTIES</strong>
        </h4>
        <p class="body-2 text-left">
          4.1 The Company is the owner or the licensee of all intellectual
          property rights for technologies constituting the Platform itself.
          <br/><br/>
          4.2 The materials published on the Platform are protected by
          applicable copyright laws. The User shall not download, copy, use, or
          modify any materials available on the Platform for any other purpose
          other than for personal use. If User downloads, copies, uses, or
          modifies materials available on the Platform for User’s personal use,
          User must destroy all such materials immediately upon completion of
          such personal use.
          <br/><br/>
          4.3 The User shall not use the Platform to violate any laws.
          <br/><br/>
          4.4 The User shall not attempt to hack, disassemble, or
          reverse-engineer the software constituting the Platform.
          <br/><br/>
          4.5 The Platform may contain content, products, or services,
          hyperlinks to third-party websites offering content, products,
          services, or advertisements (the “Third Party Content“). The Company
          bears no responsibility for the specific content presented by Third
          Party Content, and the Company shall not be liable for any damage or
          loss which may arise from the User’s engagement with Third Party
          Content.
          <br/><br/>
          4.6 The Company may develop and release software updates for the
          Platform from time to time (the “Product Updates“). The User may be
          required to download an updated version of the Platform to continue
          accessing Provider Services. The Company does not guarantee that any
          subsequent version of the Platform will operate properly on User’s
          devices (including personal computers, laptops, or mobile phones). The
          Company is not liable for any loss the User may incur due to Product
          Updates.
        </p>
        <h4 class="mt-5 mb-5 text-left">
          <strong>
            SECTION 5: DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
          </strong>
        </h4>
        <p class="body-2 text-left">
          5.1 THE USER HEREBY RELEASES THE COMPANY, ITS PRESENT AND FUTURE
          OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS, VOLUNTEERS, CONTRACTORS,
          REPRESENTATIVES, PARENTS, OWNERS, AFFILIATES, AGENTS, SUCCESSORS, AND
          ASSIGNS (COLLECTIVELY, THE “COMPANY RELATED PARTIES”) AND AGREES TO
          HOLD THE COMPANY RELATED PARTIES HARMLESS FROM ANY AND ALL CAUSES OF
          ACTION AND CLAIMS OF ANY NATURE RESULTING FROM THE PROVIDER SERVICES
          OR THE PLATFORM, INCLUDING (WITHOUT LIMITATION) ANY ACT, OMISSION,
          OPINION, RESPONSE, ADVICE, SUGGESTION, INFORMATION, AND/OR SERVICE OF
          ANY COUNSELOR AND/OR ANY OTHER CONTENT OR INFORMATION ACCESSIBLE
          THROUGH THE PLATFORM.
          <br/><br/>
          5.2 THE USER UNDERSTANDS, AGREES, AND ACKNOWLEDGES THAT THE PLATFORM
          IS PROVIDED “AS IS”, WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES OF ANY
          KIND (INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, NON-INFRINGEMENT,
          SECURITY, FITNESS FOR A PARTICULAR PURPOSE, AND ACCURACY). USE OF THE
          PLATFORM SHALL BE AT THE USER’S OWN RISK. TO THE FULLEST EXTENT OF THE
          LAW, THE COMPANY EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, BOTH
          EXPRESSED AND IMPLIED.
          <br/><br/>
          5.3 THE USER UNDERSTANDS, AGREES, AND ACKNOWLEDGES THAT NEITHER THE
          COMPANY NOR ANY OF ITS AFFILIATES SHALL BE LIABLE TO THE USER OR TO
          ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
          PUNITIVE, OR EXEMPLARY DAMAGES.
          <br/><br/>
          5.4 If Applicable Law does not allow the limitation of liability as
          set forth above, the limitation will be deemed modified solely to the
          extent necessary to comply with Applicable Law. This limitation of
          liability contained herein shall survive the termination or expiration
          of these User Terms.
        </p>
        <h4 class="mt-5 mb-5 text-left">
          <strong> SECTION 6: USER REPRESENTATIONS AND WARRANTIES </strong>
        </h4>
        <p class="body-2 text-left">
          6.1 The User represents that he or she is legally able to enter into
          these User Terms.
          <br/><br/>
          6.2 The User represents that he or she is legally able to consent to
          receive Provider Services.
          <br/><br/>
          6.3 The User represents that all the information User provides on the
          Platform, either now or in the future, is complete, accurate, and
          true. Furthermore, the User agrees that during the term of these User
          Terms, User will take all reasonable steps to maintain and update
          User’s information to ensure that all such information continues to be
          complete, accurate, and true.
          <br/><br/>
          6.4 The User is responsible for protecting the confidentiality of
          User’s password. The User is advised to change his or her password
          frequently and to take extra care in safeguarding his or her account.
          <br/><br/>
          6.5 The User shall immediately notify the Company in the event of any
          unauthorized use of User’s account, or if User is concerned that
          User’s account has been accessed by any third party.
          <br/><br/>
          6.6 The User agrees to use the Platform for the sole purpose of
          seeking and receiving Provider Services. The User may only use the
          Platform for User’s own personal use; User must not use the Platform
          for or behalf of any other person, entity, or organization.
          <br/><br/>
          6.7 The User acknowledges and agrees that the Company shall not be
          liable for any loss or damage incurred as a result of unauthorized
          access of User’s account, either with or without the User’s consent or
          knowledge.
          <br/><br/>
          6.8 The User acknowledges and agrees that the User is exclusively
          responsible for all activities performed using the User’s account. The
          User further acknowledges and agrees that the Company may hold the
          User liable for any damages or losses incurred as a result of the use
          of User’s account by any third party, whether or not such use was
          authorized by the User, and the User shall indemnify the Company for
          all relevant damages or losses.
          <br/><br/>
          6.9 The User shall indemnify, defend and hold the the Company harmless
          from and against any and all claims, losses, causes of action,
          demands, liabilities, costs, or expenses (including, but not limited
          to, litigation and reasonable attorneys’ fees and expenses) arising
          out of or relating to any of the following: (1) User’s access to or
          use of the Platform; (2) any actions made with the User’s account
          whether by the User or a third party; (3) the User’s violation of any
          of the provisions of these User Terms; (4) non-payment for any of the
          services (including Provider Services) which were provided through the
          Platform; (5) the User’s violation of any third party right,
          including, without limitation, any intellectual property right,
          publicity, confidentiality, property or privacy right. This clause
          shall survive the expiration or termination of these User Terms.
          <br/><br/>
          6.10 The User shall not use any other Account on the Platform for any
          reason. The User may only have access to and use one (1) Account.
          <br/><br/>
          6.11 The User shall not interfere with, or attempt to interfere with,
          any of the Company’s systems, services, servers, networks, or
          infrastructure (including the Platform and it’s software components).
          <br/><br/>
          6.12 The User shall not: (1) send unsolicited messages or emails on
          the Platform which advertise or promote goods or services; (2)
          interfere with or damage the Company’s software or systems through the
          use of malicious software (also known as “malware”), code, or computer
          viruses; (3) engage in unlawful, harassing, invasive, abusive,
          threatening, vulgar, obscene, racist or potentially harmful behavior
          toward others who may be using the Platform (including Counselors and
          other users); (4) publish or display content that infringes upon the
          intellectual property rights of third parties; (5) publish or display
          content that may otherwise harm or injure a third party; (6) publish
          or display any content which may constitute, proximately cause or
          encourage a crime, tort, or violate any Applicable Law or regulation;
          or (7) solicit personal or Account information from Counselors or
          other users of the platform.
          <br/><br/>
          6.13 Whenever using the Platform, the User shall not engage in conduct
          that would violate any applicable domestic or international law,
          statute, ordinance, rule, regulation or ethical code.
          <br/><br/>
          6.14 All interactions and communications between the User and any
          Counselor shall be carried out exclusively through the Platform. The
          User shall not communicate or interact with the Counselors outside the
          Platform without prior, written consent from the Company for a period
          of at least one (1) calendar year from the date of their last session
          with any Counselor on the Platform.
          <br/><br/>
          6.15 In all of its electronic communications with the Company or with
          Counselors, the User agrees to check and scan all relevant attachments
          and files for virus or malicious software.
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong>
            SECTION 7: PLATFORM INTERRUPTIONS, MODIFICATIONS, AND TERMINATION
          </strong>
        </h4>

        <p class="body-2 text-left">
          7.1 The Company may modify, suspend, disrupt or discontinue the
          Platform, any part of the Platform, or the ability to use the platform
          at any time with or without notice. The Company may also suspend or
          terminate the User’s account at its discretion. The Company shall not
          be liable for any losses or damages that may arise from any of the
          aforementioned actions.
          <br/><br/>
          7.2 The User understands and acknowledges that the Platform’s
          functionality depends in part on the performance of third-party
          internet service providers and other third-party software components,
          tools, or dependencies which may not be under the Company’s control.
          While the Company makes commercially reasonable efforts to ensure the
          Platform’s reliability and accessibility, the Company cannot guarantee
          that the Platform will be accessible, consistent, timely, or
          error-free at all times. Accordingly, the Company shall not be liable
          for any interruptions, time-lags, or disconnections that may occur
          while the User is accessing or receiving Provider Services.
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong> SECTION 8: MANDATORY ARBITRATION </strong>
        </h4>
        <p class="body-2 text-left">
          All disputes arising out of or in connection with these User Terms
          shall be finally settled under the Rules of Arbitration of the
          International Chamber of Commerce (the “Rules“) by a single arbitrator
          appointed in accordance with said Rules. The parties expressly agree,
          pursuant to Article 30(2)(b) of the Rules, that the Expedited
          Procedure Rules shall apply irrespective of the amount in dispute. The
          place of arbitration shall be in Dubai, United Arab Emirates. The
          language of the arbitration shall be English.
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong> SECTION 9: NOTICES </strong>
        </h4>
        <p class="body-2 text-left">
          9.1 The Platform is not intended for the provision of clinical
          diagnosis requiring an in-person evaluation. The Platform is not
          intended for diagnoses and/or treatments prescribing the use of drugs
          or medical treatment, and the User should disregard any such advice if
          delivered through the platform.
          <br/><br/>
          9.2 Users are urged not to disregard, avoid, or delay in obtaining
          in-person care from their doctor or other qualified professional
          because of the information or advice User received through the
          Platform.
        </p>


        <h4 class="mt-5 mb-5">
          <strong>ONLINE DATA PROTECTION POLICY</strong>
        </h4>

        <p class="body-2 text-left">
          TALKTIME MENTAL HEALTH SERVICES LIMITED and/or its affiliates and entities (collectively “we” or “us”)
          values your security and privacy. TALKTIME MENTAL HEALTH SERVICES LIMITED (the “Company”), as a DIFC
          registered entity, must comply with Data Protection Law, DIFC Law No. 5 of 2020 (the “DP Law”), and may for
          certain types of personal data processing, be subject to laws from other jurisdictions.
        </p>

        <p class="body-2 text-left">
          As such, it is the policy of the Company to respect the privacy of its website services and app users. In
          accordance with DIFC DP Law and as applicable our Terms of Use, the Company collects information about you
          when you use or access our websites and other web-based products, information or services (collectively, the
          “Website Services”) as well as through other interactions and communications you have with us, such as
          through the Company App (the “App”). This data protection policy (the “Policy”) sets out the basis on which
          any information, including any personal data, we collect from you, or you provide to us, will be processed
          by the Company. Each time you access or use the Website Services or provide us with information, by doing so
          you are accepting and, where possible, consenting to the practices described in this Policy.
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 1: SCOPE AND APPLICATION</strong>
        </h4>

        <p class="body-2 text-left">
          This Policy applies to persons anywhere in the world who access or use the Company’s Website Services or
          the App (“Users”).
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 2: COLLECTION OF INFORMATION</strong>
        </h4>

        <p class="body-2 text-left">
          2.1 This is information you give us about you by providing information or filling in forms on the App or
          any Company -owned Website Services, or by corresponding with us (for example, by telephone, e-mail or any
          other digital or electronic form). It includes for example information you provide when you register using
          the Company-provided online client portal, or download and register to use the App, search for the App in
          app stores (including but not limited to Apple App Store and Google Play Store), share data via the App’s
          social media functions, and when you report a problem with the App, or any of our Website Services. If you
          contact us, the Company will keep at least an electronic record of such correspondence, including personal
          information shared at that time. The personal information you give us may include your name, address, e-mail
          address and phone number, certain device information, username, password, residential building, work
          address, photograph and other registration information you choose to provide (“Personal Information” or
          “Personal Data”).
        </p>

        <p class="body-2 text-left">
          2.2 The Website Services or App collect and process Personal Data for specific, lawful purposes only, or
          for the performance of tasks carried out in the interests of the Company.
        </p>

        <p class="body-2 text-left">
          2.3 The Website Services or App are not targeted, intended or expected to be of use to children. Apart from
          providing information for specific services or purposes, as directed by the Company’s processes,
          User-provided contributions of content or contact information regarding or about children are expressly
          prohibited.
        </p>

        <p class="body-2 text-left">
          2.3 The Website Services or App are not targeted, intended or expected to be of use to children. Apart from
          providing information for specific services or purposes, as directed by the Company’s processes,
          User-provided contributions of content or contact information regarding or about children are expressly
          prohibited.
        </p>

        <p class="body-2 text-left">
          2.4 Each time you use our Website Services or App we will automatically collect the following information:
          (1) technical information, including the type of mobile device you use, a unique device identifier (for
          example, mobile network information, your mobile operating system, the type of mobile browser you use,
          device token, device type, time zone setting (“Device Information”); (2) details of your use of our Website
          Services or App including, but not limited to traffic data, weblogs and other communication data, and the
          resources that you access (“Log Information”); and/or (3) location information, if the Website Services or
          App uses GPS technology to determine your current location. If you wish to use the particular feature, you
          may be asked to consent to your data being used for this purpose. You can alter your consent and sharing
          selections at any time via your mobile device settings.
        </p>

        <p class="body-2 text-left">
          2.5 When you use any Website Services or the App, we may collect Personal Information as well as
          demographic information, for example information that you submit, or that we collect, which may include, but
          is not limited to, post code, hometown, gender, username, mobile network information, your mobile operating
          system, the type of mobile browser you use, time zone setting, device location, IP address, SMS data,
          transaction information, age/birth date, browsing history information, searching history information, and
          registration history information (“Demographic Information”).
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 3: USE OF PERSONAL DATA</strong>
        </h4>

        <p class="body-2 text-left">
          3.1 We may use Personal Data which you provide to us or we collect from you to: (1) Provide, maintain, and
          improve our App and Website Services, including, for example, to facilitate payments, send receipts, provide
          products and services you request (and send related information about them), develop new features, provide
          customer support to Users, authenticate users, and send administrative messages, whether information or
          required by applicable law; (2) Perform internal administration and operations, including, for example, to
          prevent fraud and abuse of our Website Services; to troubleshoot software bugs and operational problems; to
          conduct data analysis, testing, and research; and to monitor and analyze usage and activity trends; (3) Send
          you communications we think will be of interest to you, including information about products, services,
          promotions, news, and the Company’s events, where permissible under DIFC Laws and according to any other
          applicable laws; and to process contest, sweepstake, or other promotion entries and fulfill any related
          awards; (4) Notify you about changes to our App and Website Services; (5) Allow you to participate in any
          interactive features of our App or Website Services; (6) Keep our App and Website Services safe and secure;
          and/or (7) Personalize and improve the Website Services, including to provide or recommend features,
          content, social connections, referrals, and advertisements.
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 4: PROCESSING, STORAGE AND TRANSFER OF PERSONAL DATA</strong>
        </h4>

        <p class="body-2 text-left">
          4.1 We will take all steps reasonably necessary to ensure your data is processed fairly and lawfully, in
          accordance with the DP Law, other applicable laws and this Policy. By submitting your Personal Data
          (including Log, Device and / or Demographic Information), you agree to such transfer, storing or processing
          in order for the Company to perform its general administrative and regulatory functions, including but not
          limited to responding to enquiries you raise via the App or Website Services or maintaining contacts for
          future informational or promotional activities. Unless otherwise notified, the Company does not ordinarily
          engage in automated decision making when processing your Personal Data.
        </p>

        <p class="body-2 text-left">
          4.2 You agree that we have the right to transfer the Personal Data described in this Policy to and from,
          and process and store it in, the United Arab Emirates and (where applicable or required) with processors in
          other countries, some of which may have less protective privacy laws than those where you reside. Where this
          is the case, we will take appropriate security measures to protect your Personal Data in accordance with
          this Policy. All information security policies are strictly enforced.
        </p>


        <p class="body-2 text-left">
          4.3 To preserve the integrity of our databases, to carry out on-going Website Services on behalf of all
          Users, for research, analytics and statistics purposes and to ensure compliance with applicable laws and
          regulations, we retain Personal Data submitted by Users for a reasonable length of time unless otherwise
          prescribed by applicable law. The Company is not responsible for the accuracy of the information you
          provide, and will modify or update your Personal Data in our databases upon your request, as further
          outlined below. We will erase or archive your Personal Data upon request, unless we are required to retain
          it in accordance with the Company or other applicable laws or to perform agreed services. By accessing or
          using the App or Website Services, you do hereby represent and warrant that you understand that all
          information submitted by you through the App or Website Services or otherwise to the Company may be used by
          the Company in accordance with applicable laws and its policies.
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 5: SHARING OF PERSONAL DATA</strong>
        </h4>

        <p class="body-2 text-left">
          5.1 We may share Personal Data which we collect about you as described in this Policy or as described at
          the time of collection or sharing, including as follows: (1) With third parties to provide you a service you
          requested through a partnership or promotional offering made by a third party or us; (2) With third parties
          with whom you choose to let us share your Personal Data, for example other apps or websites that integrate
          with our API or Website Services, or those with an API or Service with which we integrate; and Other Types
          Of Data Sharing; (3) With the Company’s subsidiaries and affiliated entities; (4) With vendors, consultants,
          marketing and advertising partners, and other service providers who need access to such Personal Data to
          carry out work on our behalf or to perform a contract we enter into with them; (5) In response to a request
          for information by a competent authority or government entities if we determine that such disclosure is in
          accordance with, or is otherwise required by, any applicable law, regulation, or legal process; (6) With law
          enforcement officials, government entities or authorities, or other third parties as required by applicable
          law; (7) With third parties in connection with, or during negotiations of, any merger, sale of company
          assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by or
          into another company; (8) If we otherwise notify you and you consent to the sharing; and/or (9) With third
          parties in an aggregated and/or anonymized form which cannot reasonably be used to identify you
        </p>

        <p class="body-2 text-left">
          5.2 All sharing of Personal Data aligns to the extent possible with the Company’s Government Data Sharing
          Policy, which is an internal Company’s policy that governs fair and lawful sharing of Personal Data
          requested by government entities within the UAE and elsewhere.
        </p>


        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 6: YOUR RIGHTS AND CHOICES</strong>
        </h4>

        <p class="body-2 text-left">
          6.1 The Company supports Users’ legal rights to opt-out of receiving communications from us and our
          partners. You have the option to ask us not to process your Personal Data for marketing purposes and to
          remove it from our database, to not receive future communications or to no longer receive our App or Website
          Services.
        </p>

        <p class="body-2 text-left">
          6.2 You may change your preferences at any time.
        </p>

        <p class="body-2 text-left">
          6.3 Please note that we may continue to send you transactional or service-related emails despite your
          desire to not receive promotional or marketing e-mail messages. Additionally, please note that if you elect
          to opt-out of receiving promotional emails from one of our Website Services or the App, you may continue to
          receive promotional emails from our other websites, providers or other, non-affiliated marketers whose
          services you may have accessed via the Company’s Website Services or App.
        </p>

        <p class="body-2 text-left">
          6.4 Finally, while we may remove your individual contact information from our professional contacts
          database, please be aware that if such information is in a different third party’s marketing directory
          through your request or election, you will need to request removal with such third party directly.
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 7: ACCESS TO AND CORRECTION OF YOUR PERSONAL INFORMATION</strong>
        </h4>

        <p class="body-2 text-left">
          7.1 You have the right to access information held about you. Your right of access can be exercised in
          accordance with DIFC and other applicable laws. Any access request generally comes at no cost to you, but
          may, where permissible, be subject to a fee to meet any extraordinary administrative costs in providing you
          with details of the information we hold about you.
        </p>

        <p class="body-2 text-left">
          7.2 When you contact us about a potential Personal Data error, we will endeavor to confirm or verify the
          information in question, then correct verified inaccuracies and respond to the original inquiry. We will
          endeavor to send a correction notice to businesses or others whom we know to have received the inaccurate
          data, where required and / or appropriate. However, some third parties and third party sites may continue to
          process inaccurate data about you until their databases and display of data are refreshed in accordance with
          their update schedules, or until you contact them personally to ensure the correction is made in their own
          files.
        </p>

        <p class="body-2 text-left">
          7.3 You may also request that we restrict, erase or otherwise process your Personal Data in line with the
          relevant articles providing for such rights set out in the DP Law.
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 8: CHANGES TO THIS POLICY</strong>
        </h4>

        <p class="body-2 text-left">
          8.1 We may change this Policy from time to time and without notice. If we make significant changes in the
          way we treat your Personal Data, or to the Policy, we will endeavor to provide you notice through the App or
          Website Services or by some other means, such as email. Your continued use of the App or Website Services
          after such notice constitutes your acceptance of the changes. We encourage you to periodically review this
          Policy for the latest information on our privacy practices. We provide links to it through: (1) The App or
          Website Services; (2) Referencing it in our Terms of Use; and/or (3) Incorporating it into our contracts,
          agreements, and other documents as necessary or appropriate
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 9: SECURITY PRECAUTIONS</strong>
        </h4>

        <p class="body-2 text-left">
          9.1 The Company makes every effort to ensure that your Personal Data is secure on its system. The Company
          has staff dedicated to maintaining our data protection and security policies, periodically reviewing them
          and making sure that the Company’s employees are aware of our data protection and security practices.
          Unfortunately, no data transmission over the internet can be guaranteed to be 100% secure. As a result, the
          Company cannot warrant or guarantee the security of any Personal Data you transmit to us, and you do so at
          your own risk.
        </p>

        <p class="body-2 text-left">
          9.2 The Company has established policies and procedures for securely managing information and protecting
          Personal Data against unauthorized access. We continually assess our data privacy, information management
          and security practices. We do this in the following ways: (1) Establishing policies and procedures for
          securely managing information; (2) Limiting employee access to viewing only necessary information in order
          to perform his or her duties; (3) Protecting against unauthorized access to Personal Data by using data
          encryption, authentication and virus detection technology, as required; (4) Requiring service providers with
          whom we do business to comply with relevant data privacy legal and regulatory requirements; (5) Monitoring
          our websites through recognized online privacy and security organizations; and/or (6) Conducting background
          checks on employees and providing training to our employees.
        </p>

        <p class="body-2 text-left">
          9.3 If you have any further questions about our security and processing activities, please contact the Data
          Protection team / Officer or refer to our Terms of Use. To the extent permitted by applicable law, the
          Company expressly disclaims any liability that may arise should any other third parties obtain the Personal
          Data you submit.
        </p>


        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 10: COOKIES</strong>
        </h4>

        <p class="body-2 text-left">
          10.1 A cookie is a small text file that is unique to the web browser on your computer or mobile device,
          which is used to retain user preferences, and enhance browsing experience (“Cookie”). The Company uses
          Cookies to track overall site usage and enables us to provide a better user experience. We do not use
          Cookies to “see” other data on your computer or determine your email address.
        </p>

        <p class="body-2 text-left">
          10.2 Types of cookies we drop and the information collected using them include: (1) Google Tag Manager –
          helps make tag management simple, easy and reliable by allowing marketers and webmasters to deploy website
          tags all in one place; (2) Google Analytics – gives website owners the digital analytics tools needed to
          analyse data from all touchpoints in one place, for a deeper understanding of the customer experience; (3)
          Pingdom – monitors sites and servers on the internet, alerting the website owners if any problems are
          detected; and/or (4) Hotjar – by combining both analysis and feedback tools, Hotjar helps website owners
          understand what users want, care about and interact with on their website by visually representing their
          clicks, taps and scrolling behavior.
        </p>

        <p class="body-2 text-left">
          10.3 Alternatively you may wish to visit an independent source of information, , which contains
          comprehensive information on how to alter settings or delete Cookies from your computer as well as more
          general information about Cookies. For information on how to do this on the browser of your mobile phone you
          will need to refer to your handset manual or network operator for advice.
        </p>


        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 11: EXTERNAL LINKS</strong>
        </h4>

        <p class="body-2 text-left">
          11.1 The Website and the App may contain links to other websites on the Internet that are owned and
          operated by third parties (the “External Sites”). These links are provided solely as a convenience to you
          and not as an endorsement by the Company of the contents of or reliability on such External Sites. You
          acknowledge that the Company is not responsible for the availability of, or the information and content of
          any External Site. You should contact the site administrator or webmaster for those External Sites if you
          have any concerns regarding such links or the content located on such external Sites. If you decide to
          access linked third party websites, you do so at your own risk. The Company does not accept liability, and
          shall not be liable to you for any loss or damage arising from or as a result of your acting upon the
          content of another website to which you may link from the Website Services or the App.
        </p>

        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 12: THE COMPANY’s BUILDINGS SECURITY</strong>
        </h4>

        <p class="body-2 text-left">
          12.1 Building security records containing sign in and sign out information collected at the time of
          visiting and departing a Company-owned building will be maintained in accordance with this Policy.
        </p>


        <h4 class="mt-5 mb-5 text-left">
          <strong>SECTION 13: CONTACT US</strong>
        </h4>

        <p class="body-2 text-left">
          If you have any questions, comments and requests related to this Policy, or if you have any complaints
          related to how the Company processes your personal data, please contact us.
        </p>
      </v-card-text>
      <v-card-actions class="pb-6 pt-5 px-10 flex-column">
        <v-checkbox
            v-model="checkbox"
            :label="
            $t(
              'By ticking, your are confirming that you have read, understood and agree to TalkTime Terms and Conditions'
            )
          "
        ></v-checkbox>
        <v-btn
            block
            color="primary"
            @click="onAccept"
            :disabled="!checkbox"
            class="pe-3 mb-5"
        >
          {{ $t("Accept") }}
        </v-btn>

        <v-btn block text color="black" class="pe-3" @click="close">
          {{ $t("Decline") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import logoSvg from "@/components/svg/Logo";
// import {mapActions} from "vuex";

export default {
  name: "Terms",
  // components: {logoSvg},

  data: () => {
    return {
      checkbox: false,
      dialog: false,
      callbackAction: null,
    };
  },

  methods: {
    // ...mapActions("user", ["logout"]),

    open(cb = null) {
      this.dialog = true;
      this.callbackAction = cb;
    },

    close() {
      this.dialog = false;
    },

    onAccept() {
      if (typeof this.callbackAction === "function") {
        this.callbackAction();
      }
      // this.close();
    },
  },
};
</script>

<style scoped lang="scss">
h4 strong {
  color: #000;
}

::v-deep.v-btn.primary {
  box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35);
}

::v-deep.v-input--checkbox {
  .v-input__slot {
    align-items: start;

    label {
      font-size: 14px;
      color: #000;
    }
  }
}
</style>
