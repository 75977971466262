<template>
  <div>
    <Pricing v-if="showPricing" ref="PricingRef" @nextStep="pricingNextStep" />
    <FitAssessment
      v-else
      ref="FitAssessmentRef"
      :callback-action="callbackAction"
      @prevStep="fitAssessmentPrevStep"
    />
  </div>
</template>

<script>
import Pricing from "@/views/loggedIn/Alone/Pricing";
import FitAssessment from "@/views/loggedIn/Alone/FitAssessment";
import TherapistMixin from "@/mixins/TherapistMixin";

export default {
  name: "TherapistSelectionNextSteps",
  components: {
    Pricing,
    FitAssessment,
  },
  mixins: [TherapistMixin],

  data() {
    return {
      showPricing: true,
      callbackAction: null,
    };
  },

  methods: {
    pricingNextStep(cb) {
      this.showPricing = false;
      this.callbackAction = cb;
    },

    fitAssessmentPrevStep() {
      this.showPricing = true;
      this.callbackAction = null;
    },
  },

  mounted() {
    if (!this.demoTherapist) {
      this.$router.replace({ name: "TherapistSelection" });
    }
  },
};
</script>

<style scoped></style>
