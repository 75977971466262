<template>
  <v-card
    class="rounded-lg text-start pricing d-flex flex-column card-with-animation fill-height pb-4 pt-0"
    elevation="2"
  >
    <v-toolbar
      class="position-absolute tt-n-65 w-100 top-0"
      height="40"
      color="primary"
      elevation="0"
      v-if="planMapper[subscriptionKey].value === 'month_1'"
    >
      <v-toolbar-title
        class="text-center font-weight-semibold body-1 w-100 white--text"
      >
        {{ $t("Most Popular") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-chip
      color="primary"
      small
      class="ms-3 rounded-bottom-left pricing-chip opacity-100 white--text"
      v-if="planMapper[subscriptionKey].value === 'month_1'"
    >{{ $t("Save 20% (AED 350)") }}</v-chip
    >
    <v-chip
      color="primary"
      small
      class="ms-3 rounded-bottom-top-left pricing-chip opacity-100 white--text"
      v-else-if="planMapper[subscriptionKey].value === 'month_2'"
    >{{ $t("Save 25% (AED 900)") }}</v-chip
    >
    <!-- <v-card-title class="pt-0">
      <h2
        class="headline font-weight-medium primary--text text-center text--lighten-1 mt-8 mb-0 w-100"
      >
        {{ $t(planMapper[subscriptionKey].name) }}
      </h2>
    </v-card-title> -->

    <v-card-title class="py-0">

      <h5
        class="font-weight-bold px-4 mb-5 w-100"
        :class="$vuetify.rtl ? 'subtitle-2' : 'subtitle-1'"
      >
        <span v-if="!$vuetify.rtl">
          {{ $t("AED") }}
        </span>

        <template v-if="subscriptionPriceSelected">
          <del>{{ subscriptionPriceSelected.metadata['del_price'] }}</del>
          {{ subscriptionPriceSelected.metadata['1_month_price'] }}
          <span v-if="$vuetify.rtl">
            {{ $t("AED") }}
          </span>
          /
          <sub class="font-weight-light font-size-12">
              {{ $t("Month") }}
          </sub>

        </template>
      </h5>
    </v-card-title>

    <div class="px-8 has-border-bottom">
      <h4>
        {{  $t(planMapper[subscriptionKey].name) }}
      </h4>
      <br/>
    </div>

<!--    <v-btn v-if="showVariants" icon @click="showVariants = false" class="ms-5">-->
<!--      <v-icon>mdi-arrow-left</v-icon>-->
<!--    </v-btn>-->
    <div>
      <v-card-text class="px-8 pb-0">
        <v-list class="pt-0 mb-2">
          <v-list-item
            dense
            v-for="feature in planMapper[subscriptionKey].features"
            class="px-0 font-weight-semibold caption"
            :key="feature.name"
          >
            <!-- <v-icon left class="me-2 secondary--text" small>
              {{
                !feature.included ? "mdi-circle-medium" : "mdi-check-circle"
              }}</v-icon
            > -->
            <img
              v-if="feature.included"
              src="../../assets/check-primary.svg"
              class="me-2"
              width="20"
            />

            <span>
              {{ $t(feature.name) }}
            </span>
            <span v-if="!feature.included" class="ms-1 primary--text">
              <i>{{ $t("(coming soon)") }}</i>
            </span>
          </v-list-item>
        </v-list>
        <p class="body-2 mt-1" v-if="planMapper[subscriptionKey].value === 'month_1'">
          {{ $t("You will be charged AED 1,450") }}
        </p>
        <p class="body-2 mt-1" v-else-if="planMapper[subscriptionKey].value === 'month_2'">
          {{ $t("You will be charged AED 2,700") }}
        </p>
      </v-card-text>
    </div>

<!--    <div v-else class="my-auto px-8">-->
<!--      <v-btn-->
<!--        v-for="(price, i) in subscriptionPropCopy"-->
<!--        :key="price.id"-->
<!--        elevation="0"-->
<!--        :outlined="!price.value"-->
<!--        :color="!price.value ? 'muted' : 'primary'"-->
<!--        block-->
<!--        :ripple="false"-->
<!--        class="me-5 px-0 mt-3 mb-6"-->
<!--        @click="selectPlan(price, i)"-->
<!--      >-->
<!--        &lt;!&ndash; {{ price.nickname }} &ndash;&gt;-->
<!--        <div class="d-flex justify-start text-start">-->
<!--          <div-->
<!--            class="ms-3 d-flex flex-column align-start justify-center"-->
<!--            :class="-->
<!--              !price.value-->
<!--                ? 'black&#45;&#45;text text&#45;&#45;lighten-5'-->
<!--                : 'white&#45;&#45;text text&#45;&#45;darken-1'-->
<!--            "-->
<!--          >-->
<!--            <h6-->
<!--              class="subtitle-2 font-weight-bold mb-1"-->
<!--              :class="-->
<!--                !price.value-->
<!--                  ? 'black&#45;&#45;text text&#45;&#45;lighten-5'-->
<!--                  : 'white&#45;&#45;text text&#45;&#45;darken-1'-->
<!--              "-->
<!--            >-->
<!--              {{ $t(price.metadata.name) }}-->
<!--            </h6>-->
<!--            <p class="mb-0 body-2 font-weight-medium">-->
<!--              <span v-if="!$vuetify.rtl">{{ $t("AED") }}</span>-->
<!--              <template v-if="price.metadata.name === '1 Week Subscription'">-->
<!--                <span>-->
<!--                  {{ (price.unit_amount / 100) | priceFormat }}-->
<!--                </span>-->
<!--              </template>-->
<!--              <template v-if="price.metadata.name === '2 Weeks Subscription'">-->
<!--                <span>-->
<!--                  {{ (price.unit_amount / 100 / 2) | priceFormat }}-->
<!--                </span>-->
<!--              </template>-->
<!--              <template v-if="price.metadata.name === '1 Month Subscription'">-->
<!--                <span>-->
<!--                  {{ (price.unit_amount / 100 / 4) | priceFormat }}-->
<!--                </span>-->
<!--              </template>-->
<!--              <template v-if="price.metadata.name === '2 Months Subscription'">-->
<!--                <span>-->
<!--                  {{ (price.unit_amount / 100 / 8) | priceFormat }}-->
<!--                </span>-->
<!--              </template>-->
<!--              <span v-if="$vuetify.rtl">{{ $t("AED") }}</span>-->

<!--              / {{ $t("Week") }}-->
<!--              <v-chip-->
<!--                color="primary"-->
<!--                small-->
<!--                class="ms-1 white&#45;&#45;text text&#45;&#45;darken-1"-->
<!--                v-if="price.metadata.discount > 0"-->
<!--                >{{ $t("Save") }} {{ price.metadata.discount }}%</v-chip-->
<!--              >-->
<!--            </p>-->
<!--            <p class="mb-0 body-2 mt-1">-->
<!--              {{ $t("You will be charged") }}-->

<!--              <span v-if="!$vuetify.rtl">{{ $t("AED") }}</span>-->
<!--              {{ (price.unit_amount / 100) | priceFormat }}-->
<!--              <span v-if="$vuetify.rtl">{{ $t("AED") }}</span>-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </v-btn>-->
<!--    </div>-->


    <v-card-actions class="mt-auto pb-5 px-8 pt-0">
      <v-btn
        @click="onSubscribe"
        color="primary"
        elevation="0"
        block
        class="mx-auto px-5 py-3 getStartedButton"
      >
        {{ $t("Get Started") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import UserMixin from "@/mixins/UserMixin";
import therapistMixin from "@/mixins/TherapistMixin";

export default {
  name: "BillingCardV2",

  mixins: [UserMixin, therapistMixin],

  props: {
    subscriptionProp: {
      required: true,
    },

    subscriptionKey: {
      required: true,
    },
  },

  data: () => {
    return {
      showVariants: false,
      subscriptionPropCopy: [],
    };
  },

  computed: {
    subscriptionPriceSelected() {
      return this.subscriptionPropCopy.find((e) => e.value);
    },

    planMapper() {
      return {
        month_1: {
          name: this.$t("1 Month Subscription"),
          value: "month_1",
          features: [
            {
              name: this.$t("4 X 50min Live Sessions / month"),
              included: true,
            },
            {
              name: this.$t("20min Free Initial Consultation"),
              included: true,
            },
            {
              name: this.$t("Chat Messaging"),
              included: true,
            },
            {
              name: this.$t("Future-Self Journal"),
              included: true,
            },
          ],
        },

        month_2: {
          name: this.$t("2 Months Subscription"),
          value: "month_2",
          features: [
            {
              name: this.$t("8 X 50min Live Sessions every 2 months"),
              included: true,
            },
            {
              name: this.$t("20min Free Initial Consultation"),
              included: true,
            },
            {
              name: this.$t("Chat Messaging"),
              included: true,
            },
            {
              name: this.$t("Future-Self Journal"),
              included: true,
            },
            // {
            //   name: this.$t("Wellness Hub"),
            //   included: false,
            // },
            // {
            //   name: this.$t("Progress Tracker"),
            //   included: false,
            // },
          ],
        },
      };
    },

    price() {
      let subscr = this.subscriptionPropCopy[0];
      if (subscr) return subscr.unit_amount / 100;

      return "";
    },
  },

  filters: {
    priceFormat(value) {
      if (value > 1000) return (value / 1000).toFixed(3).replace(".", ",");
      return value;
    },
  },

  methods: {
    ...mapActions("subscription", ["subscribeAction"]),

    selectPlan(price, index) {
      price.value = true;
      this.subscriptionPropCopy.forEach((e, i) => {
        if (i !== index) e.value = false;
      });
      // this.sortCopy()
      this.$forceUpdate();
    },

    sortCopy() {
      this.subscriptionPropCopy.sort((a, b) => {
        if (a.nickname < b.nickname) {
          return -1;
        }
        if (a.nickname > b.nickname) {
          return 1;
        }
        return 0;
      });

      this.subscriptionPropCopy = this.subscriptionPropCopy.filter(
        (e) => e.active
      );
    },

    async onSubscribe() {
      this.$emit("onAction", this.onSubscribeAction.bind(this));
    },

    async onSubscribeAction({ teenTerms } = {}) {
      let therapistId = this.demoTherapist?._id;

      if (!therapistId) {
        therapistId = this.myTherapistId;
      }

      let data = {
        therapistId: therapistId,
        packageName: this.planMapper[this.subscriptionKey].value,
        priceId: this.subscriptionPriceSelected.id,
      };

      if (teenTerms) {
        data.teenTerms = teenTerms;
      }

      let subscribeActionRes = await this.subscribeAction(data);

      let datalayer = window?.dataLayer;
      if (datalayer) {
        datalayer.push({
          event:
            "subscribe" +
            "therapist: " +
            therapistId +
            "packageName: " +
            data.packageName,
        });
      }

      window.location.replace(subscribeActionRes.url);
      // window.open(subscribeActionRes.url, '_blank');
    },

    setFirstItem() {
      this.subscriptionPropCopy.map((item) => {
        item.value = false;
      });
      this.subscriptionPropCopy[0].value = true;
    },
  },

  mounted() {
    this.subscriptionPropCopy = [...this.subscriptionProp];

    this.sortCopy();
    this.setFirstItem();

    // console.log(this.planMapper)
  },
};
</script>

<style scoped>
.text-right {
  text-align: right !important;
}
::v-deep.v-btn {
  height: auto !important;
}
::v-deep.v-btn.getStartedButton {
  box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35) !important;
}

::v-deep.v-btn:not(.getStartedButton) .v-btn__content {
  white-space: normal;
  flex-wrap: wrap;
  flex: auto;
  justify-content: start;
  padding: 10px;
}
::v-deep.v-btn.primary .v-chip.primary {
  background-color: white !important;
  color: #3475b2 !important;
  /* color:  */
}

.tt-n-65 {
  transform: translateY(-65%);
}
</style>

